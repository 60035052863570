import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Dataset from '@/components/Dataset'
import Griddap from '@/components/Griddap'
import Tabledap from '@/components/Tabledap'
import Library from '@/components/Library'

import Share from '@/components/Share'

Vue.use(Router)

const router = new Router({
    routes: [{
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/share',
            name: 'Share',
            component: Share,
        },
        {
            path: '/dataset/:name',
            name: 'Dataset',
            component: Dataset,
        },
        {
            path: '/data/griddap/:name',
            name: 'Griddap',
            component: Griddap,
        },
        {
            path: '/data/:name',
            name: 'Tabledap',
            component: Tabledap,
        },
        {
            path: '/data',
            name: 'Library',
            component: Library,
        },
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
      }
})


export default router