<template>
  <v-app>
    <div style="background: #e4e4e4">
      <v-overlay :value="error"></v-overlay>
      <v-dialog v-model="error" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5" style="word-break: initial">
            Hubo un problema al obtener la información
          </v-card-title>
          <v-card-text
            >Verifica que el conjunto de datos consultado es válido o reintenta
            nuevamente más tarde</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="error = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="panel_descarga"></v-overlay>
      <v-dialog v-model="panel_descarga" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5" style="word-break: initial">
            Su descarga comenzará en breve.
          </v-card-title>
          <v-card-text
            >Tomar en consideración que algunos conjuntos de datos pueden demorar
            más en cargar</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="panel_descarga = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row style="margin-top: 5px; min-height: 100vh">
        <v-col cols="2" class="panel-izq" style="background: white">
          <div style="position: sticky; top: 80px">
            <v-divider></v-divider>
            <v-avatar
              color="primary"
              size="80"
              style="margin-top: 10px; margin-bottom: 5px"
            >
              <span style="color: white">
                {{ autor ? autor.charAt(0) : null }}</span
              ></v-avatar
            >
            <br />
            <span style="font-size: small; padding: 10px"> {{ autor }} </span>
            <br />
            <div style="font-size: 12px">
              <!-- a v-on:click="irA()"> Ver más datos </a> <br /><br /> -->
              <br />
              <v-divider></v-divider>
            </div>

            <v-list dense>
              <v-subheader>CATEGORÍAS</v-subheader>
              <v-list-item-group v-model="section" color="primary">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  v-on:click="changeTab(i)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-col>
        <v-col
          cols="10"
          class="panel-der"
          style="
            border: 10px;
            border-radius: 10px;
            border-color: #563636;
            box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px;
          "
        >
          <div
            class="content"
            style="
              border: 10px;
              border-radius: 10px;
              border-color: #563636;
              box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px;
            "
          >
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <div
              class="dataset-header"
              v-bind:style="{
                backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.7), 
              rgba(0, 0, 0, 0.7)
            ), url('${this.imagen}')`,
              }"
            >
              <div>
                <img
                  src="images/OCC_color-blanco.png"
                  alt="Observatorio de Cambio Climático"
                  style="max-width: 250px"
                />

                <p style="color: white; font-size: 25px">{{ title }}</p>
              </div>
            </div>
            <div class="dataset-body">
              <v-tabs color="#000099" v-model="tab" background-color="#eeeeee">
                <v-tab href="#tab-1">Descripción</v-tab>
                <v-tab href="#tab-2">Descarga</v-tab>
                <v-tab href="#tab-3">Metadatos</v-tab>
                <!-- <v-tab href="#tab-4">Graficar</v-tab> -->
                <v-tab href="#tab-4">Discusión</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <div class="main-content">
                <v-row no-gutters>
                  <v-col cols="12" sm="9">
                    <v-tabs-items v-model="tab">
                      <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        v-for="i in 5"
                        :key="i"
                        :value="'tab-' + i"
                      >
                        <div v-if="i == 1" class="section-dataset">
                          <v-row no-gutters>
                            <v-col>
                              <h2 style="text-align: left; margin-bottom: 5px">
                                <v-icon small> mdi-script-text </v-icon>
                                Descripción
                              </h2>
                              <p
                                style="
                                  text-align: left;
                                  background: #fafafa;
                                  padding: 10px;
                                  border: 10px #563636;
                                  box-shadow: 0 0 0 1px rgb(0 0 0 / 8%);
                                  border-radius: 10px;
                                "
                                v-html="description"
                              ></p>
                              <h2 style="text-align: left; margin-bottom: 5px">
                                <v-icon small> mdi-newspaper-variant </v-icon>
                                Keywords
                              </h2>
                              <p
                                style="
                                  text-align: left;
                                  background: #fafafa;
                                  padding: 10px;
                                  border: 10px #563636;
                                  box-shadow: 0 0 0 1px rgb(0 0 0 / 8%);
                                  border-radius: 10px;
                                  font-size: small;
                                "
                                v-html="keywords"
                              ></p>
                              <h2 style="text-align: left; margin-bottom: 5px">
                                <v-icon small> mdi-newspaper-variant </v-icon>
                                Licencia
                              </h2>
                              <p
                                style="
                                  text-align: left;
                                  background: #fafafa;
                                  padding: 10px;
                                  border: 10px #563636;
                                  box-shadow: 0 0 0 1px rgb(0 0 0 / 8%);
                                  border-radius: 10px;
                                  font-size: small;
                                "
                                v-html="license"
                              ></p>
                            </v-col>
                            <v-col>
                              <a
                                :href="erddap_graph_link"
                                download
                                style="color: white; text-decoration: none"
                              >
                                <img
                                  :src="
                                    'images/graph_' +
                                    $route.params.name +
                                    '.png'
                                  "
                                  alt="Dirección Meteorologica"
                                  style="
                                    max-width: 400px;
                                    padding: 40px;
                                    padding-bottom: 0px;
                                  " /></a
                              ><br />
                              <span style="font-size: small">
                                Haz click en la imagen para realizar más
                                gráficos
                              </span>
                            </v-col>
                          </v-row>

                          <h2 style="text-align: left; margin-bottom: 5px">
                            <v-icon small> mdi-newspaper-variant </v-icon>
                            Wiki
                          </h2>
                          <h3
                            v-if="title"
                            v-html="title"
                            style="text-align: left"
                          ></h3>
                          <Wiki
                            v-if="title"
                            :title="title"
                            style="
                              text-align: left;
                              background: rgb(253 253 253);
                              padding: 20px;
                              border: 10px rgb(86, 54, 54);
                              box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                              border-radius: 10px;
                            "
                          />
                          <br />
                        </div>
                        <div v-else-if="i == 2" class="section-dataset">
                          <div style="text-align: left">
                            Seleccione las variables a descargar
                            <br />
                            <br />

                            <v-container
                              style="
                                border: 10px rgb(86, 54, 54);
                                border-radius: 10px;
                                box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                                padding: 20px;
                                padding-top: 0px;
                                background: #00009903;
                              "
                            >
                              <v-row>
                                <v-col
                                  v-for="(element, index) in dimensions"
                                  :key="index"
                                  cols="12"
                                  sm="3"
                                >
                                  <v-checkbox
                                    :label="element['Variable Name']"
                                    v-model="element['Value']"
                                    disabled
                                  ></v-checkbox>
                                  <span
                                    v-if="element['comment']"
                                    style="font-size: small"
                                  >
                                    {{ element["comment"] }}
                                  </span>
                                  <br v-if="element['comment']" />
                                  <span style="font-size: small">
                                    <b>Unidades: </b>{{ element.units }}
                                  </span>
                                  <br />
                                  <span style="font-size: small">
                                    <b>Min: </b
                                    >{{
                                      element.standard_name == "time"
                                        ? new Date(
                                            Number(
                                              element.actual_range.split(",")[0]
                                            ) * 1000
                                          )
                                        : element.actual_range.split(",")[0]
                                    }}
                                  </span>
                                  <br />
                                  <span style="font-size: small">
                                    <b>Max: </b
                                    >{{
                                      element.standard_name == "time"
                                        ? new Date(
                                            Number(
                                              element.actual_range.split(",")[1]
                                            ) * 1000
                                          )
                                        : element.actual_range.split(",")[1]
                                    }}
                                  </span>
                                </v-col>

                                <v-col
                                  v-for="(element, key) in variables"
                                  :key="key"
                                  cols="12"
                                  sm="3"
                                >
                                  <v-checkbox
                                    :label="element['Variable Name']"
                                    v-model="element['Value']"
                                  ></v-checkbox>
                                  <span
                                    v-if="element['comment']"
                                    style="font-size: small"
                                  >
                                    {{ element["comment"] }}
                                  </span>
                                  <br v-if="element['comment']" />
                                  <span style="font-size: small">
                                    <b>Unidades: </b>{{ element.units }}
                                  </span>
                                </v-col>
                              </v-row>
                            </v-container>
                            <div>
                              <v-row>
                                <v-col style="padding: 20px">
                                  <b> Formato: </b>
                                  <p style="font-size: small">
                                    Selecciona el formato para la descarga de
                                    los datos
                                  </p>
                                  <div
                                    style="
                                      text-align: left;
                                      background: #fafafa;
                                      padding: 10px;
                                      border: 10px #563636;
                                      box-shadow: 0 0 0 1px rgb(0 0 0 / 8%);
                                    "
                                  >
                                    <v-select
                                      v-model="format"
                                      :items="formats"
                                      style="
                                        border-radius: 5px;
                                        text-align: center;
                                      "
                                      dense
                                      prepend-icon="mdi-file"
                                    ></v-select>
                                  </div>
                                </v-col>

                                <v-col>
                                  <br />
                                  <p style="font-size: small">
                                    Haz click en el siguiente bóton para
                                    descargar:
                                  </p>

                                  <div
                                    style="
                                      background-color: rgb(0, 0, 153);
                                      border-color: rgb(0, 0, 153);
                                      min-height: 48px;
                                      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px
                                        0px 1px;
                                      display: flex;
                                      position: relative;
                                    "
                                  >
                                    <div
                                      class="text-center col col-12"
                                      style="color: white"
                                      v-on:click="panel_descarga = true"
                                    >
                                      <a
                                        :href="download_link"
                                        download
                                        style="
                                          color: white;
                                          text-decoration: none;
                                        "
                                      >
                                        Descargar archivo
                                      </a>
                                    </div>
                                    <img
                                      src="https://www.minciencia.gob.cl/static/images/footer-detail-left.svg"
                                      height="48px"
                                      style="position: absolute"
                                    /><img
                                      height="48px"
                                      src="https://www.minciencia.gob.cl/static/images/footer-detail-right.svg"
                                      style="position: absolute; right: 0px"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                          <br />
                        </div>
                        <div class="section-dataset" v-else-if="i == 3">
                          <h2 style="text-align: left">Metadatos</h2>

                          <div style="text-align: left">
                            Los metadatos asociados al presente conjunto son:
                          </div>
                          <table
                            style="
                              width: 100%;
                              font-size: small;
                              margin-top: 10px;
                            "
                          >
                            <tbody>
                              <tr
                                v-for="(element, index) in metadatos.rows"
                                :key="index"
                                style="text-align: left"
                              >
                                <th
                                  style="
                                    width: 25%;
                                    text-align: left;
                                    background: rgb(250, 250, 250);
                                    padding: 10px;
                                    border: 1px solid rgb(215 215 215);
                                    border-style: solid;
                                    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px
                                      1px;
                                  "
                                >
                                  {{ element["Attribute Name"] }}
                                </th>
                                <td
                                  style="
                                    width: 75%;
                                    padding: 5px;
                                    border: 1px solid rgb(215 215 215);
                                    border-style: solid;
                                    border-width: 1px;
                                    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px;
                                  "
                                >
                                  {{ element["Value"] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                        </div>

                        <div style="min-height: 600px" v-else-if="i == 4">
                          <div
                            style="
                              padding-left: 30px;
                              padding-right: 30px;
                              padding-top: 10px;
                            "
                          >
                            <v-alert dense type="info" dismissible>
                              La información que se muestra en esta sección
                              corresponde a la discusión contribuida por los
                              usuarios en la Wiki. Para agregar, selecciona la
                              opción "Editar"
                            </v-alert>
                          </div>
                          <div
                            style="
                              text-align: left;
                              padding-left: 10px;
                              padding-right: 10px;
                            "
                          >
                            <v-row>
                              <v-col>
                                <h3>{{ "Discusión:" + title }}</h3>
                              </v-col>
                              <v-col cols="2">
                                <div>
                                  <a
                                    :href="
                                      'https://wiki.occ.minciencia.gob.cl/index.php?title=Discusi%C3%B3n:' +
                                      title +
                                      '&action=edit'
                                    "
                                    download
                                    style="color: black; text-decoration: none"
                                    target="_blank"
                                  >
                                    Editar <v-icon>mdi-plus-circle</v-icon>
                                  </a>
                                </div>
                              </v-col>
                            </v-row>
                            <Wiki :title="'Discusión:' + title" />
                          </div>
                          <br />
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <div class="right-panel">
                      <div style="position: sticky; top: 100px">
                        <b> Detalles </b>

                        <div
                          style="
                            background-color: rgb(0, 0, 153);
                            border-color: rgb(0, 0, 153);
                            min-height: 48px;
                            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                            display: flex;
                            position: relative;
                            border-radius: 48px;
                            margin-top: 10px;
                          "
                        >
                          <div
                            class="text-center col col-12"
                            style="color: white"
                          >
                            <a
                              :href="erddap_link"
                              download
                              style="color: white; text-decoration: none"
                            >
                              Ver en ERDDAP
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon dark small v-bind="attrs" v-on="on">
                                    mdi-information
                                  </v-icon>
                                </template>
                                <span
                                  >ERDDAP es un servidor de datos, desarrollado
                                  por el<br />
                                  NOAA de EE.UU., que entrega una forma <br />
                                  simple y consistente de descargar <br />datos
                                  científicos.</span
                                >
                              </v-tooltip>
                            </a>
                          </div>
                        </div>

                        <div
                          style="
                            background-color: rgb(0, 0, 153);
                            border-color: rgb(0, 0, 153);
                            min-height: 48px;
                            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                            display: flex;
                            position: relative;
                            border-radius: 48px;
                            margin-top: 10px;
                          "
                        >
                          <div
                            class="text-center col col-12"
                            style="color: white"
                          >
                            <a
                              target="_blank"
                              :href="wiki_link"
                              download
                              style="color: white; text-decoration: none"
                            >
                              Ver en Wiki
                            </a>
                          </div>
                        </div>

                        <br />
                        <b>Autor</b>
                        <br />
                        {{ autor }}
                        <!--<br>
                          <br>
                          <b>Licencia</b>
                          <br>
                          {{ licencia }} -->
                        <br />
                        <br />

                        <b>Dataset ID</b>
                        <br />
                        {{ dataset_id }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import "vue2-datepicker/index.css";
import Wiki from "./Wiki.vue";

export default {
  name: "Data",
  components: {
    Wiki,
  },
  created() {
    this.getMetadata();
  },
  data() {
    return {
      nombre: undefined,
      items3: ["Foo", "Bar", "Fizz", "Buzz"],
      descripcion: undefined,
      fuente: undefined,
      metadata: undefined,
      autor: undefined,
      autor_imagen: undefined,
      licencia: undefined,
      imagen:
        "https://live.mrf.io/statics/i/ps/www.muylinux.com/wp-content/uploads/2014/09/mountain1.jpg?width=1200&enable=upscale",
      time_coverage_end: undefined,
      last_dataset: undefined,
      history: [],
      headers: [],
      dataTable: [],
      preview: {
        data: [],
        schema: {
          fields: [],
        },
      },
      graphs: [],
      items: [
        { text: "Inicio", icon: "mdi-home" },
        { text: "Descarga", icon: "mdi-download" },
        { text: "Metadatos", icon: "mdi-code-tags" },
        //{ text: 'Graficar', icon: 'mdi-chart-bell-curve-cumulative' },
        { text: "Discusión", icon: "mdi-message" },

        /* { text: 'Populares', icon: 'mdi-star' },
        { text: 'Agregados recientemente', icon: 'mdi-email-newsletter' },*/
      ],
      tab: null,
      datacollection: null,
      explorar: true,
      dialog: false,
      dialog_filter: false,
      dialog_pandas: false,
      dialog_notebook: false,
      switch_filter: false,
      previsualizacion: 0,
      tablas: [],
      selectElement: undefined,
      paginaHistorial: 1,
      paginaComentarios: 1,
      graphtab: 0,
      tab_filter: 0,
      loading_graphs: false,
      datetime_start: undefined,
      datetime_end: undefined,
      columnasaDescargar: undefined,
      error: false,
      loading: false,
      table_last_index: undefined,
      table_autor: undefined,
      table_description: undefined,
      table_licencia: undefined,
      table_min_date: undefined,
      table_max_date: undefined,
      table_headers: [],
      table_rows: [],
      section: 0,
      notebook: { cells: [] },
      metadatos: {
        rows: [],
        headers: [],
      },
      variables: [],
      dimensions: [],
      description: null,
      dataset_id: null,
      keywords: null,
      format: "csv",
      formats: ["csv", "json", "mat", "nc"],
      anotaciones: undefined,
      source: undefined,
      license: undefined,
      title: undefined,
      show: false,
      discusion: {},
      discusion_edit: undefined,
      discusion_title: null,
      panel_descarga: false,
    };
  },
  watch: {
    selectElement: async function () {
      this.loading = true;
      let tables;
      try {
        tables = (await this.axios.get("/tables/" + this.selectElement)).data;
      } catch (error) {
        console.log(error);
        tables = {};
      } finally {
        this.table_headers = tables.columns ? tables.columns : [];
        this.table_rows = tables.rows ? tables.rows : [];
        this.table_last_index = tables.last_update;
        this.table_description = tables.description;
        this.table_min_date = tables.min_date;
        this.table_max_date = tables.max_date;
        this.loading = false;
      }
    },
    dialog_filter: function () {
      this.columnasaDescargar = this.dropdownDescargas;
    },
    tab_filter: function () {
      this.selectElement = this.tablas[this.tab_filter].name;
    },
    tab: function () {
      this.section = this.tab ? parseInt(this.tab.split("-")[1]) - 1 : 0;
    },
  },
  computed: {
    espaciosHistorial: function () {
      if (Math.floor(this.history.length / 6) == this.history.length / 6) {
        return this.history.length / 6;
      } else {
        return Math.floor(this.history.length / 6) + 1;
      }
    },
    selectedHistorial: function () {
      return this.history.slice(
        6 * (this.paginaHistorial - 1),
        6 * (this.paginaHistorial - 1) + 6
      );
    },

    dropdownDescargas: function () {
      return this.table_headers.map((x) => x.name);
    },
    dropdownColumnas: function () {
      return this.tablas ? this.tablas.map((x) => x.name) : [];
    },
    download_link: function () {
      let query = "";
      for (const element of this.variables) {
        if (element["Value"] == true) {
          if (query == "") {
            query = element["Variable Name"];
          } else {
            query = query + "," + element["Variable Name"];
          }
        }
      }
      return (
        "https://datos.occ.minciencia.gob.cl/erddap/tabledap/" +
        this.$route.params.name +
        "." +
        this.format +
        "?" +
        query
      );
    },
    download_link_csv: function () {
      let query = "";
      for (const element of this.variables) {
        if (element["Value"] == true) {
          if (query == "") {
            query = element["Variable Name"];
          } else {
            query = query + "," + element["Variable Name"];
          }
        }
      }
      return (
        "https://datos.occ.minciencia.gob.cl/erddap/tabledap/" +
        this.$route.params.name +
        ".csv?" +
        query
      );
    },
    erddap_link: function () {
      return (
        "https://datos.occ.minciencia.gob.cl/erddap/tabledap/" +
        this.$route.params.name +
        ".html"
      );
    },
    wiki_link: function () {
      return "https://wiki.occ.minciencia.gob.cl/index.php/" + this.title;
    },
    erddap_graph_link: function () {
      return (
        "https://datos.occ.minciencia.gob.cl/erddap/tabledap/" +
        this.$route.params.name +
        ".graph"
      );
    },
  },
  methods: {
    getMetadata: async function () {
      this.loading = true;
      let result;
      try {
        result = (
          await this.axios.get("/erddap/metadata/" + this.$route.params.name)
        ).data;
      } catch (error) {
        result = {
          rows: [],
          headers: [],
        };
        this.error = true;
      } finally {
        this.metadatos = result;
        this.variables = this.metadatos.rows.filter(function (el) {
          return el["Row Type"] == "variable";
        });
        this.variables.forEach((element) => (element["Value"] = true));
        for (const element of this.variables) {
          for (const attribute of this.metadatos.rows.filter(function (el) {
            return (
              el["Row Type"] == "attribute" &&
              el["Variable Name"] == element["Variable Name"]
            );
          })) {
            element[attribute["Attribute Name"]] = attribute["Value"];
          }
        }

        this.dimensions = this.metadatos.rows.filter(function (el) {
          return el["Row Type"] == "dimension";
        });
        this.dimensions.forEach((element) => (element["Value"] = true));
        for (const element of this.dimensions) {
          for (const attribute of this.metadatos.rows.filter(function (el) {
            return (
              el["Row Type"] == "attribute" &&
              el["Variable Name"] == element["Variable Name"]
            );
          })) {
            element[attribute["Attribute Name"]] = attribute["Value"];
          }
        }

        this.description =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "summary";
          }).length > 0
            ? this.metadatos.rows
                .filter(function (el) {
                  return el["Attribute Name"] == "summary";
                })[0]
                .Value.replaceAll("\\u00c3\\u00a1", "á")
                .replaceAll("\\u00c3\\u00b1", "ñ")
                .replaceAll("\\u00c3\\u00ad", "í")
                .replaceAll("\\u00c3\\u00a9", "é")
                .replaceAll("\\u00c3\\u00ba", "ú")
                .replaceAll("\\u00c3\\u00b3", "ó")
                .replaceAll("\\ufffd\\ufffd", "í")
            : undefined;

        this.license =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "license";
          }).length > 0
            ? this.metadatos.rows
                .filter(function (el) {
                  return el["Attribute Name"] == "license";
                })[0]
                .Value.replaceAll("\\u00c3\\u00a1", "á")
                .replaceAll("\\u00c3\\u00b1", "ñ")
                .replaceAll("\\u00c3\\u00ad", "í")
                .replaceAll("\\u00c3\\u00a9", "é")
                .replaceAll("\\u00c3\\u00ba", "ú")
                .replaceAll("\\u00c3\\u00b3", "ó")
                .replaceAll("\\ufffd\\ufffd", "í")
            : undefined;

        this.source =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "source";
          }).length > 0
            ? this.metadatos.rows.filter(function (el) {
                return el["Attribute Name"] == "source";
              })[0].Value
            : undefined;
        this.autor =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "institution";
          }).length > 0
            ? this.metadatos.rows
                .filter(function (el) {
                  return el["Attribute Name"] == "institution";
                })[0]
                .Value.replaceAll("\\u00c3\\u00a1", "á")
                .replaceAll("\\u00c3\\u00b1", "ñ")
                .replaceAll("\\u00c3\\u00ad", "í")
                .replaceAll("\\u00c3\\u00a9", "é")
                .replaceAll("\\u00c3\\u00ba", "ú")
                .replaceAll("\\u00c3\\u00b3", "ó")
                .replaceAll("\\ufffd\\ufffd", "ó")
            : undefined;
        this.title =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "title";
          }).length > 0
            ? this.metadatos.rows
                .filter(function (el) {
                  return el["Attribute Name"] == "title";
                })[0]
                .Value.replaceAll("\\u00c3\\u00a1", "á")
                .replaceAll("\\u00c3\\u00b1", "ñ")
                .replaceAll("\\u00c3\\u00ad", "í")
                .replaceAll("\\u00c3\\u00a9", "é")
                .replaceAll("\\u00c3\\u00ba", "ú")
                .replaceAll("\\u00c3\\u00b3", "ó")
                .replaceAll("\\ufffd\\ufffd", "ó")
            : undefined;
        this.time_coverage_end =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "time_coverage_end";
          }).length > 0
            ? this.metadatos.rows.filter(function (el) {
                return el["Attribute Name"] == "time_coverage_end";
              })[0].Value
            : undefined;
        this.keywords =
          this.metadatos.rows.filter(function (el) {
            return el["Attribute Name"] == "keywords";
          }).length > 0
            ? this.metadatos.rows
                .filter(function (el) {
                  return el["Attribute Name"] == "keywords";
                })[0]
                .Value.replaceAll("\\u00c3\\u00a1", "á")
                .replaceAll("\\u00c3\\u00b1", "ñ")
                .replaceAll("\\u00c3\\u00ad", "í")
                .replaceAll("\\u00c3\\u00a9", "é")
                .replaceAll("\\u00c3\\u00ba", "ú")
                .replaceAll("\\u00c3\\u00b3", "ó")
                .replaceAll("\\ufffd\\ufffd", "ó")
            : undefined;
        this.dataset_id = this.$route.params.name;
        this.loading = false;
        document.title =  this.title ? this.title + " - Observatorio de Cambio Climático" : "Observatorio de Cambio Climático" 

        this.$forceUpdate();
      }
    },
    changeTab(i) {
      this.tab = "tab-" + (i + 1);
    },

    getImgUrl(png) {
      return "data:image/png;base64," + png;
    },
    irA() {
      this.$router.push({
        path: "/data",
        query: {
          institucion: this.autor,
        },
      });
    },
  },
};
</script>

<style>
.v-dialog__container {
  display: unset !important;
}
.v-dialog--custom {
  width: 100% !important;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .v-dialog--custom {
    width: 700px !important;
  }
}

@media screen and (max-width: 768px) {
  .list-text {
    display: none;
  }
}

.content {
  background: #d9d9d926;
}

.dataset-header {
  min-height: 300px;
  background: #e6e6e6;
  border-radius: 5px;
  background-size: cover;

  text-align: center;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  min-height: 300px;
  padding: 20px;
  padding-bottom: 0px;
}
.right-panel {
  height: 100px;
  background: "#F2F2F2";
  padding: 20px;
  height: 100%;
  border-left: #cfcfcf;
  border-left-width: 1px;
  border-left-style: solid;
}

.boton-descarga {
  background: cadetblue;
  color: white;
  width: 100px;
  margin: auto;
  border-radius: 10px;
  padding: 2px;
  cursor: pointer;
  margin-top: 3px;
}

@media only screen and (max-width: 1024px) {
  .content {
    padding: 0px 0px 0px 0px;
  }
  .content-home {
    padding: 0px 30px 0px 30px;
  }
}
.explorar-header {
  margin: auto;
  text-align: left;
  border-radius: 10px;
  padding: 2px;
  cursor: pointer;
  margin-top: 3px;
}
.v-timeline--dense:before {
  right: auto;
  left: 47px;
}
.v-data-table-header-mobile {
  display: none !important;
}
.select-element {
  background: #e6e6e6 !important;
  cursor: pointer;
}
.normal-element {
  cursor: pointer;
}

.jp-code {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  background: #f7f7f7;
  line-height: 1.21429em;
  margin-top: 10px;
  width: 1200px;
}
.jp-markdown {
  margin-top: 10px;
  margin-bottom: 10px;
}

.v-messages.theme--light {
  display: none;
}

.section-dataset {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
