<template>
  <div id="app">
    <Header />
    <router-view style="margin-top: 56px; background: #e1e1e1"></router-view>
    <v-footer
      style="min-height: 150px; box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px; z-index: 100"
      padless
      color="#000033"
    >
        <v-col style="color: white"  cols="5"> 
          <img
          src="images/minciencia-blanco.png"
          style="max-width: 120px; position:relative; float: right"
        /> </v-col>
        <v-col class="text-center" cols="7" style="color: white; text-align: left">
          {{ new Date().getFullYear() }} —
          <strong>Observatorio de Cambio Climático</strong>
          <br />
          Ministerio de Ciencia, Tecnología, Conocimiento e Innovación
        </v-col>
        <img
          src="https://www.minciencia.gob.cl/static/images/footer-detail-left.svg"
          style="position: absolute"
        />
        <img
          src="https://www.minciencia.gob.cl/static/images/footer-detail-right.svg"
          style="position: absolute; right: 0"
        />
    </v-footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
  methods: {
    goDataset: function () {},
  },
};
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif !important; */
  /*font-family: Roboto, sans-serif !important; */
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  /*padding: 0px 100px 0px 0px;*/
  min-height: 400px;
  margin-right: 50px;
}

.dataset-body {
  min-height: 200px;
  background: white;
}

.v-text-field__details {
  display: none !important;
}
</style>
