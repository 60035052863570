<template>
  <v-app>
    <div style="background: #e4e4e4">
      <v-row style="margin-top: 5px; min-height: 100vh">
        <v-col cols="2" class="panel-izq" style="background: white">
          <div style="
                position: fixed;
                width: 15.666666%;
            ">
            <v-list dense>
              <v-subheader>CATEGORÍAS</v-subheader>
              <v-list-item-group v-model="section" color="primary">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  v-on:click="changeTab(i)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-col>
        <v-col
          cols="10"
          class="panel-der"
          style="
            border: 10px;
            border-radius: 10px;
            border-color: #563636;
            box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px;
          "
        >
          <div
            class="content"
            style="
              border: 10px;
              border-radius: 10px;
              border-color: #563636;
              box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px;
            "
          >
            <v-overlay :value="loading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <v-overlay :value="dialog_pandas"></v-overlay>
            <v-dialog v-model="dialog_pandas" width="300px">
              <div style="padding: 10px; background: #353535; color: white">
                <v-row>
                  <v-col></v-col>
                  <v-col></v-col>
                  <v-col>
                    <v-col style="color: white">
                      <v-icon
                        v-on:click="dialog_pandas = false"
                        style="color: white"
                        color="green darken-2"
                      >
                        mdi-close</v-icon
                      ></v-col
                    >
                  </v-col>
                </v-row>
                <span style="color: #cf52ef">import </span> pandas
                <span style="color: #cf52ef">as</span> pd
                <br />
                <br />
                data = pd.read_csv("<span style="color: #fb8c00">{{
                  last_dataset
                }}</span
                >")
                <br />
                <br />
                data.head()
                <br />
                <br />
              </div>
            </v-dialog>

            <v-overlay :value="dialog_notebook"></v-overlay>
            <v-dialog v-model="dialog_notebook" width="100%">
              <div style="padding: 50px; background: white; text-align: left">
                <div v-for="(element, index) in notebook.cells" :key="index">
                  <div v-if="element.cell_type == 'code'" style="display: flex">
                    <div
                      style="
                        min-width: 10ex;
                        text-align: right;
                        padding: 10px;
                        color: #303f9f;
                      "
                    >
                      In [{{ element.execution_count }}]:
                    </div>
                    <div class="jp-code">
                      <pre
                        style="display: block"
                        v-for="(code, index) in element.source"
                        :key="index"
                        v-html="code"
                      ></pre>
                      <br />
                    </div>
                    <br />
                  </div>
                  <div
                    v-else-if="element.cell_type == 'markdown'"
                    class="jp-markdown"
                    style="display: flex"
                  >
                    <div
                      style="
                        min-width: 10ex;
                        text-align: right;
                        padding: 10px;
                        color: #303f9f;
                      "
                    ></div>
                    <div>
                      <vue-markdown
                        v-for="(code, index) in element.source"
                        :key="index"
                      >
                        {{ code }}
                      </vue-markdown>
                    </div>
                  </div>

                  <!-- OUTPUT -->
                  <div
                    v-if="element.outputs && element.outputs.length > 0"
                    style="display: flex"
                  >
                    <div
                      style="
                        min-width: 10ex;
                        text-align: right;
                        padding: 10px;
                        color: #303f9f;
                      "
                    ></div>
                    <div
                      style="display: block"
                      v-for="(output, index) in element.outputs"
                      :key="index"
                    >
                      <div v-if="output.html">
                        <div
                          style="display: block; color: #000"
                          v-for="(ht, index) in output.html"
                          :key="index"
                          v-html="ht"
                        >
                          <span v-html="ht"></span>
                        </div>
                      </div>
                      <div v-else-if="output.output_type == 'stream'">
                        <span
                          style="display: block; color: #000"
                          v-for="(text, index) in output.text"
                          :key="index"
                        >
                          {{ text }}
                        </span>
                      </div>
                      <div v-else-if="output.output_type == 'display_data'">
                        <img :src="getImgUrl(output.png)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-dialog>
            <div
              class="dataset-header"
              v-bind:style="{
                backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.7), 
              rgba(0, 0, 0, 0.7)
            ), url('${this.imagen}')`,
              }"
            >
              <p style="color: white; font-size: 25px">{{ nombre }}</p>
            </div>
            <div class="dataset-body">
              <v-tabs color="#5f9da0" v-model="tab">
                <v-tab href="#tab-1">Descripción</v-tab>
                <v-tab href="#tab-2">Descarga</v-tab>
                <v-tab href="#tab-3">Trabajos</v-tab>
                <v-tab href="#tab-4">Comentarios</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <div class="main-content">
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <v-tabs-items v-model="tab">
                      <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        v-for="i in 4"
                        :key="i"
                        :value="'tab-' + i"
                      >
                        <div v-if="i == 1">
                          <h2 style="text-align: left">
                            <v-icon small> mdi-script-text </v-icon> Descripción
                          </h2>
                          <p style="text-align: left" v-html="descripcion"></p>
                          <h2 style="text-align: left">
                            <v-icon small> mdi-newspaper-variant </v-icon>
                            Fuente
                          </h2>
                          <p style="text-align: left" v-html="fuente"></p>
                          <br />
                        </div>
                        <div class="time-panel" v-else-if="i == 2">
                          <h2>{{ selectElement }}</h2>
                          <v-tabs
                            color="#000000"
                            show-arrows
                            v-model="tab_filter"
                          >
                            <v-tab
                              v-for="item in dropdownColumnas"
                              :key="item"
                              v-model="tab_filter"
                            >
                              {{ item }}
                            </v-tab>
                          </v-tabs>

                          <v-tabs-items>
                            <v-tab-item
                              v-for="item in dropdownColumnas"
                              :key="item"
                            >
                              <v-card flat>
                                <v-card-text>{{ item }}</v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                          <div style="text-align: left">
                            <br />
                            <v-icon small> mdi-table-large </v-icon
                            ><b> Previsualizacion:</b>
                            <v-data-table
                              :key="selectElement"
                              :headers="table_headers"
                              :items="table_rows"
                              :items-per-page="10"
                              class="elevation-1"
                              dense
                              hide-default-footer
                            >
                            </v-data-table>
                            <br />
                          </div>
                          <v-btn
                            elevation="2"
                            plain
                            outlined
                            color="#000000"
                            style="margin-left: 10px"
                            v-on:click="dialog_filter = true"
                          >
                            Descargar tabla
                          </v-btn>
                        </div>
                        <div class="time-panel" v-else-if="i == 3">
                          <h2 style="text-align: left">Trabajos</h2>
                          <br />
                          <div style="text-align: left">
                            Trabajos realizados por otros usuarios:
                          </div>
                          <br />
                          <div
                            style="display: flex; margin-bottom: 30px"
                            v-for="element in selectedComentarios"
                            :key="element.date"
                          >
                            <div style="width: 50px">
                              <v-avatar size="36px">
                                <img
                                  alt="Avatar"
                                  src="https://avatars0.githubusercontent.com/u/9064064?v=4&s=460"
                                />
                              </v-avatar>
                            </div>
                            <div
                              style="
                                text-align: left;
                                padding: 20px;
                                box-shadow: 2px 2px 8px 0 #c1c8d2;
                                width: 90%;
                                min-height: 100px;
                              "
                            >
                              <div>04 de octubre</div>
                              <h2>Titulo</h2>
                              <v-chip>Python</v-chip>
                              <v-chip>Visualizacion</v-chip> <br />
                              <br />
                              <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Vero, velit. Ipsum repudiandae
                                reprehenderit facilis officiis suscipit itaque
                                porro non qui magni quam, impedit magnam aliquid
                                vitae veniam corporis minima placeat!
                              </p>
                              <div style="float: right">
                                <v-icon>mdi-star</v-icon> 2
                                <v-icon>mdi-message</v-icon> 0
                              </div>
                              <v-btn
                                rounded
                                color="primary"
                                dark
                                v-on:click="openNotebook()"
                              >
                                Ver trabajo
                              </v-btn>
                            </div>
                          </div>
                          <br />
                          <br />
                          <v-pagination
                            color="#5f9ea0"
                            v-model="paginaHistorial"
                            :length="espaciosHistorial"
                            :total-visible="6"
                          ></v-pagination>
                        </div>
                        <div style="min-height: 600px" v-else-if="i == 4">
                          <h2 style="text-align: left">Comentarios</h2>
                          <br />
                          <v-row>
                            <v-col>
                              <p style="text-align: left">
                                Aquí podrás ver los últimos comentarios de este
                                dataset:
                              </p>
                            </v-col>
                            <v-col cols="2">
                              <div>
                                Agregar <v-icon>mdi-plus-circle</v-icon>
                              </div>
                            </v-col>
                          </v-row>
                          <br />
                          <div
                            style="display: flex; margin-bottom: 30px"
                            v-for="element in selectedComentarios"
                            :key="element.date"
                          >
                            <div style="width: 50px">
                              <v-avatar size="36px">
                                <img
                                  alt="Avatar"
                                  src="https://avatars0.githubusercontent.com/u/9064064?v=4&s=460"
                                />
                              </v-avatar>
                            </div>
                            <div
                              style="
                                text-align: left;
                                padding: 20px;
                                box-shadow: 2px 2px 8px 0 #c1c8d2;
                                width: 90%;
                                min-height: 100px;
                              "
                            >
                              <h2>Titulo</h2>
                              <p>
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Vero, velit. Ipsum repudiandae
                                reprehenderit facilis officiis suscipit itaque
                                porro non qui magni quam, impedit magnam aliquid
                                vitae veniam corporis minima placeat!
                              </p>
                              <div style="float: right">
                                <v-icon>mdi-message</v-icon> 0
                              </div>
                            </div>
                          </div>
                          <br />
                          <v-pagination
                            color="#5f9ea0"
                            v-model="paginaComentarios"
                            :length="espaciosComentarios"
                            :total-visible="5"
                          ></v-pagination>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="right-panel">
                      <div>
                        <div><b>Descargar</b></div>
                        <v-btn
                          elevation="2"
                          color="#5e9ca0"
                          style="color: white"
                          v-on:click="tab = 'tab-2'"
                        >
                          Datos disponibles
                        </v-btn>
                        <br />

                        <br />
                        <b>Autor</b>
                        <br />
                        <v-avatar size="48">
                          <img :src="autor_imagen" alt="Autor" />
                        </v-avatar>
                        <br />
                        {{ autor }}
                        <br />
                        <br />
                        <b>Licencia</b>
                        <br />
                        {{ licencia }}
                        <br />
                        <br />
                        <b>Última actualización</b>
                        <br />
                        {{ index_time }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
//import LineChart from './charts/BarB.js'
//import 'vue2-datepicker/index.css';
import "vue2-datepicker/index.css";
import VueMarkdown from "vue-markdown";

export default {
  name: "Dataset",
  components: {
    //   LineChart
    VueMarkdown,
  },
  created() {
    this.getData();
  },
  data() {
    return {
      nombre: undefined,
      items3: ["Foo", "Bar", "Fizz", "Buzz"],
      descripcion: undefined,
      fuente: undefined,
      metadata: undefined,
      autor: undefined,
      autor_imagen: undefined,
      licencia: undefined,
      imagen: undefined,
      index_time: undefined,
      last_dataset: undefined,
      history: [],
      headers: [],
      dataTable: [],
      preview: {
        data: [],
        schema: {
          fields: [],
        },
      },
      graphs: [],
      comentarios: [
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 1",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 2",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 3",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 4",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 5",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 6",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
        {
          autor: "Ignacio",
          date: "1626148800",
          title: "Comentario acerca de formato tabla 7",
          descripcion:
            "Al revisar la columna correspondiente a la ciudad de Antofagasta",
        },
      ],
      items: [
        { text: "Inicio", icon: "mdi-home" },
        { text: "Descarga", icon: "mdi-download" },
        { text: "Trabajos", icon: "mdi-code-tags" },
        { text: "Comentarios", icon: "mdi-message" },

        /* { text: 'Populares', icon: 'mdi-star' },
        { text: 'Agregados recientemente', icon: 'mdi-email-newsletter' },*/
      ],
      tab: null,
      datacollection: null,
      explorar: true,
      dialog: false,
      dialog_filter: false,
      dialog_pandas: false,
      dialog_notebook: false,
      switch_filter: false,
      previsualizacion: 0,
      tablas: [],
      selectElement: undefined,
      paginaHistorial: 1,
      paginaComentarios: 1,
      graphtab: 0,
      tab_filter: 0,
      loading_graphs: false,
      datetime_start: undefined,
      datetime_end: undefined,
      columnasaDescargar: undefined,
      error: false,
      loading: false,
      table_last_index: undefined,
      table_autor: undefined,
      table_description: undefined,
      table_licencia: undefined,
      table_min_date: undefined,
      table_max_date: undefined,
      table_headers: [],
      table_rows: [],
      section: 0,
      notebook: { cells: [] },
    };
  },
  watch: {
    selectElement: async function () {
      this.loading = true;
      let tables;
      try {
        tables = (
          await this.axios.get(
             "/tables/" + this.selectElement
          )
        ).data;
      } catch (error) {
        console.log(error);
        tables = {};
      } finally {
        this.table_headers = tables.columns ? tables.columns : [];
        this.table_rows = tables.rows ? tables.rows : [];
        this.table_last_index = tables.last_update;
        this.table_description = tables.description;
        this.table_min_date = tables.min_date;
        this.table_max_date = tables.max_date;
        this.loading = false;
      }
    },
    dialog_filter: function () {
      this.columnasaDescargar = this.dropdownDescargas;
    },
    tab_filter: function () {
      this.selectElement = this.tablas[this.tab_filter].name;
    },
    tab: function () {
      this.section = this.tab ? parseInt(this.tab.split("-")[1]) - 1 : 0;
    },
  },
  computed: {
    espaciosHistorial: function () {
      if (Math.floor(this.history.length / 6) == this.history.length / 6) {
        return this.history.length / 6;
      } else {
        return Math.floor(this.history.length / 6) + 1;
      }
    },
    selectedHistorial: function () {
      return this.history.slice(
        6 * (this.paginaHistorial - 1),
        6 * (this.paginaHistorial - 1) + 6
      );
    },
    espaciosComentarios: function () {
      if (
        Math.floor(this.comentarios.length / 3) ==
        this.comentarios.length / 3
      ) {
        return this.comentarios.length / 3;
      } else {
        return Math.floor(this.comentarios.length / 3) + 1;
      }
    },
    selectedComentarios: function () {
      return this.comentarios.slice(
        3 * (this.paginaComentarios - 1),
        3 * (this.paginaComentarios - 1) + 3
      );
    },
    dropdownDescargas: function () {
      return this.table_headers.map((x) => x.name);
    },
    dropdownColumnas: function () {
      return this.tablas ? this.tablas.map((x) => x.name) : [];
    },
  },
  methods: {
    getData: async function () {
      this.loading = true;
      let result;
      try {
        result = (
          await this.axios.get(
            
              "/dataset/" +
              this.$route.params.name
          )
        ).data;
      } catch (error) {
        result = {};
      } finally {
        this.autor = result.autor;
        this.autor_imagen =
          
          "/dataset/image/" +
          result.autor_imagen;
        this.descripcion = result.descripcion;
        this.fuente = result.fuente;
        this.history = result.history ? result.history : [];
        this.imagen = result.imagen;
        this.licencia = result.licencia;
        this.nombre = result.nombre;
        this.tablas = result.tablas;

        this.selectElement = this.tablas ? this.tablas[0].name : undefined;
        this.last_dataset = result.last_dataset;
        this.index_time = result.index_time;

        this.loading = false;
        this.loading = true;
        let tables;
        try {
          tables = (
            await this.axios.get(
               "/tables/" + this.selectElement
            )
          ).data;
        } catch (error) {
          tables = {};
          this.loading = false;
        } finally {
          this.table_headers = tables.columns ? tables.columns : [];
          this.table_rows = tables.rows ? tables.rows : [];
          this.table_last_index = tables.last_update;
          this.table_description = tables.description;
          this.table_min_date = tables.min_date;
          this.table_max_date = tables.max_date;
          this.loading = false;
        }
      }
    },
    getGraphs: async function () {
      this.loading_graphs = true;
      let result;
      try {
        result = (
          await this.axios.get(
            
              "/graphs/" +
              this.$route.params.name
          )
        ).data;
      } catch (error) {
        result = {};
      } finally {
        this.loading_graphs = false;
        this.graphs = result.graphs;
      }
    },
    getGraph(grafico) {
      if (this.selectedGraph[grafico]) {
        return {
          labels: this.selectedGraph[grafico].index,
          datasets: [
            {
              label: grafico,
              backgroundColor: "#f87979",
              data: this.selectedGraph[grafico].data,
            },
          ],
        };
      }
      return undefined;
    },
    descargarDataset() {
      this.error = false;
      this.loading = true;
      this.axios
        .get(
           "/generation/" + this.selectElement,
          {
            params: {
              startDate: this.datetime_start
                ? this.datetime_start.toDateString()
                : undefined,
              endDate: this.datetime_end
                ? this.datetime_end.toDateString()
                : undefined,
              columns: this.columnasaDescargar,
            },
          }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.selectElement + ".csv");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    descargarConsolidado() {
      this.loading = true;
      this.axios
        .get(
          
            "/dataset/download/" +
            this.$route.params.name,
          { responseType: "blob" }
        )
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", this.$route.params.name + ".zip");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeTab(i) {
      this.tab = "tab-" + (i + 1);
    },
    getNotebook: async function () {
      this.loading_graphs = true;
      let result;
      try {
        result = (
          await this.axios.get(
            "https://raw.githubusercontent.com/ibm-et/jupyter-samples/master/airline/Exploration%20of%20Airline%20On-Time%20Performance.ipynb"
          )
        ).data;

        //result = (await this.axios.get("https://raw.githubusercontent.com/google-research/google-research/master/scann/docs/example.ipynb")).data
      } catch (error) {
        result = {};
      } finally {
        this.notebook = result;
      }
    },
    openNotebook: async function () {
      await this.getNotebook();
      if (this.notebook.nbformat == 3) {
        this.notebook.cells = this.notebook.worksheets[0].cells;
        this.notebook.cells.forEach((value, index1, array1) => {
          if (value.cell_type == "code") {
            array1[index1].input.forEach(function (part, index, array) {
              if (array[index].charAt(0) == "#") {
                array[index] =
                  "<span style='color: #408080; font-style: italic;'>" +
                  array[index] +
                  "</span>";
              } else {
                array[index] = array[index].replace(/(?:\r\n|\r|\n)/g, "<br>");
                array[index] = array[index].replace(
                  /"(.*?)"/g,
                  '<span style="color: #BA2121;">"$1"</span>'
                );
                array[index] = array[index].replace(
                  /'(.*?)'/g,
                  "<span style='color: #BA2121;'>'$1'</span>"
                );
                array[index] = array[index].replace(
                  /import/g,
                  "<span style='color: green; font-weight: 600'>import</span>"
                );
                array[index] = array[index].replace(
                  /print/g,
                  "<span style='color: green; font-weight: 600'>print</span>"
                );
                array[index] = array[index].replace(
                  /list/g,
                  "<span style='color: green; font-weight: 600'>list</span>"
                );
                array[index] = array[index].replace(
                  /with/g,
                  "<span style='color: green; font-weight: 600'>with</span>"
                );
              }
            });
            array1[index1].source = array1[index1].input;
            array1[index1].execution_count = array1[index1].prompt_number;
          }
        });
      }
      if (this.notebook.nbformat == 4) {
        this.notebook.cells.forEach((value, index1, array1) => {
          if (value.cell_type == "code") {
            array1[index1].source.forEach(function (part, index, array) {
              if (array[index].charAt(0) == "#") {
                array[index] =
                  "<span style='color: #408080; font-style: italic;'>" +
                  array[index] +
                  "</span>";
              } else {
                array[index] = array[index].replace(/(?:\r\n|\r|\n)/g, "<br>");
                array[index] = array[index].replace(
                  /"(.*?)"/g,
                  '<span style="color: #BA2121;">"$1"</span>'
                );
                array[index] = array[index].replace(
                  /'(.*?)'/g,
                  "<span style='color: #BA2121;'>'$1'</span>"
                );
                array[index] = array[index].replace(
                  /import/g,
                  "<span style='color: green; font-weight: 600'>import</span>"
                );
                array[index] = array[index].replace(
                  /print/g,
                  "<span style='color: green; font-weight: 600'>print</span>"
                );
                array[index] = array[index].replace(
                  /list/g,
                  "<span style='color: green; font-weight: 600'>list</span>"
                );
                array[index] = array[index].replace(
                  /with/g,
                  "<span style='color: green; font-weight: 600'>with</span>"
                );
              }
            });
          }
        });
      }
      this.dialog_notebook = true;
    },
    getImgUrl(png) {
      return "data:image/png;base64," + png;
    },
  },
};
</script>

<style>
.v-dialog__container {
  display: unset !important;
}
.v-dialog--custom {
  width: 100% !important;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .v-dialog--custom {
    width: 700px !important;
  }
}

@media screen and (max-width: 768px) {
  .list-text {
    display: none;
  }
}

.content {
  background: #d9d9d926;
}

.dataset-header {
  min-height: 300px;
  background: #e6e6e6;
  border-radius: 5px;
  background-size: cover;

  text-align: center;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.boton-descarga {
  background: cadetblue;
  color: white;
  width: 100px;
  margin: auto;
  border-radius: 10px;
  padding: 2px;
  cursor: pointer;
  margin-top: 3px;
}

@media only screen and (max-width: 1024px) {
  .content {
    padding: 0px 0px 0px 0px;
  }
  .content-home {
    padding: 0px 30px 0px 30px;
  }
}
.explorar-header {
  margin: auto;
  text-align: left;
  border-radius: 10px;
  padding: 2px;
  cursor: pointer;
  margin-top: 3px;
}
.v-timeline--dense:before {
  right: auto;
  left: 47px;
}
.v-data-table-header-mobile {
  display: none !important;
}
.select-element {
  background: #e6e6e6 !important;
  cursor: pointer;
}
.normal-element {
  cursor: pointer;
}


.jp-code {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  background: #f7f7f7;
  line-height: 1.21429em;
  margin-top: 10px;
  width: 1200px;
}
.jp-markdown {
  margin-top: 10px;
  margin-bottom: 10px;
}

.v-slide-group__prev--disabled{
    display: none !important;
}
</style>
