<template>
  <div>
    <v-app-bar
      absolute
      style="
        background-color: white;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 200;
      "
    >
      <div class="container">
        <a href="/" class="navbar-logo">
          <img src="../assets/logo.svg" alt="" />
        </a>
      </div>
      <v-spacer></v-spacer>
      <div class="content-header-normal">
        <a
          href="/"
          style="margin-right: 30px; color: #000000; text-decoration: none"
        >
          Inicio
        </a>
        <a
          href="https://www.minciencia.gob.cl/"
          style="margin-right: 30px; color: #000000; text-decoration: none"
        >
          El Ministerio
        </a>
        <a
          href="/#/data"
          style="margin-right: 30px; color: #000000; text-decoration: none"
        >
          Datos
        </a>
        <a
          href="https://wiki.occ.minciencia.gob.cl/"
          style="margin-right: 30px; color: #000000; text-decoration: none"
        >
          Wiki
        </a>
        <!--<a
          href="https://www.minciencia.gob.cl/el-ministerio/mision-y-vision/"
          style="margin-right: 30px; color: #000000; text-decoration: none;"
        >
          Acerca de
        </a> -->
        <a
          href="https://www.minciencia.gob.cl/contacto/"
          style="margin-right: 30px; color: #000000; text-decoration: none"
        >
          Contacto
        </a>
      </div>
      <v-app-bar-nav-icon
        class="content-header-mobile"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list style="margin-top: 56px" nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title>
              <a href="/" style="margin-right: 30px; color: #000000">
                Inicio
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <a
                href="https://www.minciencia.gob.cl/"
                style="margin-right: 30px; color: #000000"
              >
                El Ministerio
              </a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <a
                href="https://www.minciencia.gob.cl/el-ministerio/mision-y-vision/"
                style="margin-right: 30px; color: #000000"
              >
                Acerca de
              </a>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <a
                href="https://www.minciencia.gob.cl/contacto/"
                style="margin-right: 30px; color: #000000"
              >
                Contacto
              </a>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavHeader",
  props: {
    msg: String,
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.container {
  margin: 0px 20px 0px 20px;
  max-width: 700px;
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .content-header-normal {
    display: none;
  }
  .content-header-mobile {
    display: block !important;
  }
}
.content-header-normal {
  min-width: 500px;
}
.content-header-mobile {
  display: none;
}
</style>
