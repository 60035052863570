<template>
  <v-app>
    <div>
      <v-overlay :value="dialog"></v-overlay>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5" style="word-break: initial">
            Conjuntos de datos próximamente disponibles
          </v-card-title>
          <v-card-text
            >Actualmente estamos trabajando en la incorporación de conjuntos de
            datos a la categoría seleccionada. Mientras tanto, prueba con otras
            categorías.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="dialog = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-overlay :value="avanzada"></v-overlay>
      <v-dialog
        v-model="avanzada"
        persistent
        max-width="290"
        style="z-index: 10000"
      >
        <v-card>
          <v-card-title class="text-h5"> <b> Búsqueda </b> </v-card-title>

          <v-card-text style="text-align: left">
            <b> Texto de búsqueda: </b>
            <v-textarea
              outlined
              label="Texto a buscar"
              v-model="text_busqueda"
            ></v-textarea>

            <b> Instituciones: </b>
            <v-select
              :items="arrayInstituciones"
              label="Institución a buscar"
              outlined
              v-model="institution_busqueda"
            ></v-select>

            <b> Keywords: </b>
            <v-select
              :items="arrayCategorias"
              label="Keyword a buscar"
              outlined
              v-model="keyword_busqueda"
            ></v-select>

            <b> Variables: </b>
            <v-select
              :items="arrayVariables"
              label="Variable a buscar"
              outlined
              v-model="variable_busqueda"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="
                avanzada = false;
                limpiarBusqueda();
              "
            >
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="
                avanzada = false;
                realizarBusqueda();
              "
            >
              Buscar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        class="home-header"
        style="
          width: 100%;
          height: 350px;
          background-size: cover !important;
          background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
            url('https://www.minciencia.gob.cl/uploads/filer_public_thumbnails/filer_public/23/27/23270f20-e863-495d-b376-a165566df2c0/whatsapp_image_2021-08-12_at_42325_pm_1.jpeg__1280x854_q85_subsampling-2.jpg');
          background-size: cover;
        "
      >
        <p>
          <img
            src="images/OCC_color-blanco.png"
            alt="Observatorio de Cambio Climático"
            style="max-width: 300px"
          />

          <br />
          <span style="font-size: 40px"><b> Catálogo de datos</b></span>
          <br />
          <br />
          <span style="max-width: 10px">
            Aquí puedes buscar dentro de los conjuntos de datos presentes en el
            Observatorio</span
          >
          <br />
        </p>

        <br />
      </div>
      <div>
        <div
          class="banner-home"
          style="min-height: 100px; position: sticky; z-index: 10; top: 55px"
        >
          <div style="margin: auto; width: 400px">
            <v-row align="center" justify="space-around">
              <div
                style="
                  padding: 30px;
                  padding: 30px;
                  border-right-style: solid;
                  margin-top: 10px;
                "
              >
                CATÁLOGO DE DATOS
              </div>
              <v-col>
                <div class="btn-ctgry-hm" v-on:click="irAHome()">
                  <v-icon large color="white"> mdi-home</v-icon>
                  <div style="font-size: small">Ir a inicio</div>
                </div>
              </v-col>
              <v-col>
                <div class="btn-ctgry-hm" @click="avanzada = true">
                  <v-icon large color="white"> mdi-magnify</v-icon>
                  <div style="font-size: small">Búsqueda</div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="content-home">
          <v-overlay style="z-index: 100000" :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-row class="fitted">
            <v-col
              cols="3"
              class="panel-izq"
              style="
                background: white;
                padding: 30px;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              <div style="position: sticky; top: 175px">
                <h2 style="text-align: left">
                  <v-icon> mdi-filter </v-icon> Filtros
                </h2>
                <div
                  style="padding: 10 16px; font-size: 12px; text-align: left"
                >
                  Selecciona hasta un filtro por categoría haciendo click en el
                </div>
                <br />
                <v-divider></v-divider>
                <br />
                <div
                  style="text-align: left; font-size: 1rem; margin-bottom: 7px"
                >
                  <b> Instituciones </b>
                </div>

                <div
                  style="
                    margin-bottom: 7px;
                    margin-bottom: 7px;
                    background: whitesmoke;
                    padding: 10px;
                  "
                >
                  <div
                    v-for="(element, index) in institucionesActivas"
                    :key="index"
                    style="text-align: left"
                  >
                    <v-chip
                      v-if="element.text == institucion_filtro"
                      class="ma-2"
                      style="font-size: 0.85rem; margin-top: 2px"
                      small
                      close
                      @click:close="
                        institucion_filtro = undefined;
                        removeFilter(element, 'institucion');
                      "
                    >
                      {{ element.text.substring(0, 15) }}
                    </v-chip>
                    <div
                      v-else
                      style="
                        text-align: left;
                        font-size: 0.85rem;
                        color: rgba(0, 0, 0, 0.6);
                        overflow: auto;
                      "
                      v-on:click="
                        institucion_filtro = element.text;
                        addFilter(element, 'institucion');
                      "
                    >
                      {{ element.text }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    institucionesActivas.length < filters.institutions.length
                  "
                  style="text-align: right; font-size: 0.85rem"
                >
                  <b style="cursor: pointer" v-on:click="institutions++">
                    Ver más
                  </b>
                </div>
                <br />

                <v-divider></v-divider>
                <br />
                <div style="text-align: left; font-size: 1rem">
                  <b> Variables </b>
                </div>
                <div
                  style="
                    margin-bottom: 7px;
                    margin-bottom: 7px;
                    background: whitesmoke;
                    padding: 10px;
                  "
                >
                  <div
                    v-for="(element, index) in variablesActivas"
                    :key="index"
                    style="text-align: left"
                  >
                    <v-chip
                      v-if="element.text == variable_filtro"
                      class="ma-2"
                      style="font-size: 0.85rem; margin-top: 2px"
                      small
                      close
                      @click:close="
                        variable_filtro = undefined;
                        removeFilter(element, 'variable');
                      "
                    >
                      {{ element.text }}
                    </v-chip>
                    <div
                      v-else
                      style="
                        text-align: left;
                        font-size: 0.85rem;
                        color: rgba(0, 0, 0, 0.6);
                        overflow: auto;
                        cursor: pointer;
                      "
                      v-on:click="
                        variable_filtro = element.text;
                        addFilter(element, 'variable');
                      "
                    >
                      {{ element.text }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="variablesActivas.length < filters.variables.length"
                  style="text-align: right; font-size: 0.85rem"
                >
                  <b style="cursor: pointer" v-on:click="variables++">
                    Ver más
                  </b>
                </div>
                <br />
                <v-divider></v-divider>
                <br />

                <div style="text-align: left; font-size: 1rem">
                  <b> Keywords </b>
                </div>
                <div
                  style="
                    margin-bottom: 7px;
                    background: whitesmoke;
                    padding: 10px;
                  "
                >
                  <div
                    v-for="(element, index) in keywordsActivas"
                    :key="index"
                    style="text-align: left"
                  >
                    <v-chip
                      v-if="element.text == keyword_filtro"
                      class="ma-2"
                      style="font-size: 0.85rem; margin-top: 2px"
                      small
                      close
                      @click:close="
                        keyword_filtro = undefined;
                        removeFilter(element, 'keywords');
                      "
                    >
                      {{ element.text }}
                    </v-chip>
                    <div
                      v-else
                      style="
                        text-align: left;
                        font-size: 0.85rem;
                        color: rgba(0, 0, 0, 0.6);
                        overflow: auto;
                        cursor: pointer;
                      "
                      v-on:click="
                        keyword_filtro = element.text;
                        addFilter(element, 'keywords');
                      "
                    >
                      {{ element.text }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="keywordsActivas.length < filters.categorias.length"
                  style="text-align: right; font-size: 0.85rem"
                >
                  <b style="cursor: pointer" v-on:click="keywords++">
                    Ver más
                  </b>
                </div>
                <br />
                <v-divider></v-divider>

                <div v-on:click="applyFilters()" class="btn-container">
                  <!-- <div
                    :class="
                      this.filters_institucion.length > 0 ||
                      this.filters_variables.length > 0 ||
                      this.filters_keywords.length > 0
                        ? 'btn-default'
                        : 'btn-default--none'
                    "
                  > -->
                  <div
                    :class="
                      this.$route.query.institutions ==
                        this.institucion_filtro &&
                      this.$route.query.variables == this.variable_filtro &&
                      this.$route.query.keywords == this.keyword_filtro
                        ? 'btn-default--none'
                        : 'btn-default'
                    "
                  >
                    <div class="text-center col col-12">Aplicar filtros</div>
                  </div>
                </div>
                <div></div>
              </div>
            </v-col>
            <v-col cols="9" class="panel-der">
              <div class="banner" style="background: white">
                <div
                  style="
                    position: sticky;
                    top: 165px;
                    background: white;
                    z-index: 100;
                    display: none;
                  "
                >
                  <h2 style="text-align: left; padding-top: 10px">
                    Resultados
                  </h2>
                  <br />
                  <v-text-field
                    outlined
                    label="Buscar dataset"
                    prepend-inner-icon="mdi-magnify"
                    v-model="busqueda"
                    style="color: white"
                    append-icon="mdi-send"
                  ></v-text-field>
                  <v-divider></v-divider>
                </div>
                <div>
                  <br />
                  <div
                    style="
                      padding: 0 16px;
                      font-size: small;
                      margin-bottom: 10px;
                    "
                  >
                    <h4>Listado de datasets:</h4>
                    Se encontraron un total de
                    {{ resultados.length - 1 }} conjuntos de datos
                  </div>
                  <v-divider></v-divider>
                  <v-list>
                    <template>
                      <div
                        v-for="(item, index) in resultadoPagina"
                        :key="index"
                      >
                        <v-list-item
                          v-if="
                            item.Title !=
                            '* The List of All Active Datasets in this ERDDAP *'
                          "
                        >
                          <!-- <v-list-item-avatar> </v-list-item-avatar> -->

                          <v-list-item-content>
                            <v-list-item-title>
                              <div style="display: flex">
                                <div>
                                  <v-avatar
                                    v-if="
                                      item.Institution.replaceAll(
                                        '\\u00c3\\u00a1',
                                        'á'
                                      )
                                        .replaceAll('\\u00c3\\u00b1', 'ñ')
                                        .replaceAll('\\u00c3\\u00ad', 'í')
                                        .replaceAll('\\u00c3\\u00a9', 'é')
                                        .replaceAll('\\u00c3\\u00ba', 'ú')
                                        .replaceAll('\\u00c3\\u00b3', 'ó')
                                        .replaceAll('\\ufffd\\ufffd', 'ó') ==
                                      'Centro de Estudios Avanzados en Zonas Aridas'
                                    "
                                    size="65"
                                    style="cursor: pointer"
                                    v-on:click="goDataset(item)"
                                  >
                                    <img src="images/CEAZA.png" />
                                  </v-avatar>
                                  <v-avatar
                                    v-else-if="
                                      item.Institution.replaceAll(
                                        '\\u00c3\\u00a1',
                                        'á'
                                      )
                                        .replaceAll('\\u00c3\\u00b1', 'ñ')
                                        .replaceAll('\\u00c3\\u00ad', 'í')
                                        .replaceAll('\\u00c3\\u00a9', 'é')
                                        .replaceAll('\\u00c3\\u00ba', 'ú')
                                        .replaceAll('\\u00c3\\u00b3', 'ó')
                                        .replaceAll('\\ufffd\\ufffd', 'ó') ==
                                      'Dirección Meteorológica de Chile'
                                    "
                                    size="65"
                                    style="cursor: pointer"
                                    v-on:click="goDataset(item)"
                                  >
                                    <img src="images/DMC.png" />
                                  </v-avatar>
                                  <v-avatar
                                    v-else-if="
                                      item.Institution.replaceAll(
                                        '\\u00c3\\u00a1',
                                        'á'
                                      )
                                        .replaceAll('\\u00c3\\u00b1', 'ñ')
                                        .replaceAll('\\u00c3\\u00ad', 'í')
                                        .replaceAll('\\u00c3\\u00a9', 'é')
                                        .replaceAll('\\u00c3\\u00ba', 'ú')
                                        .replaceAll('\\u00c3\\u00b3', 'ó')
                                        .replaceAll('\\ufffd\\ufffd', 'ó') ==
                                      'Servicio Nacional de Calidad del Aire'
                                    "
                                    size="65"
                                    style="cursor: pointer"
                                    v-on:click="goDataset(item)"
                                  >
                                    <img src="images/SINCA.png" />
                                  </v-avatar>
                                </div>
                                <div style="margin-left: 10px">
                                  <b
                                    style="cursor: pointer"
                                    v-on:click="goDataset(item)"
                                    >{{
                                      item.Title.replaceAll(
                                        "\\u00c3\\u00b1",
                                        "ñ"
                                      )
                                        .replaceAll("\\u00c3\\u00ad", "í")
                                        .replaceAll("\\u00c3\\u00a9", "é")
                                        .replaceAll("\\u00c3\\u00ba", "ú")
                                        .replaceAll("\\u00c3\\u00b3", "ó")
                                        .replaceAll("\\ufffd\\ufffd", "ó")
                                    }}
                                  </b>
                                  <br />
                                  <span
                                    style="font-size: 0.85rem; cursor: pointer"
                                    v-on:click="goDataset(item)"
                                  >
                                    <b>{{
                                      item.Institution.replaceAll(
                                        "\\u00c3\\u00a1",
                                        "á"
                                      )
                                        .replaceAll("\\u00c3\\u00b1", "ñ")
                                        .replaceAll("\\u00c3\\u00ad", "í")
                                        .replaceAll("\\u00c3\\u00a9", "é")
                                        .replaceAll("\\u00c3\\u00ba", "ú")
                                        .replaceAll("\\u00c3\\u00b3", "ó")
                                    }}</b>
                                  </span>
                                  <div
                                    style="
                                      font-size: 0.85rem;
                                      margin-top: 5px;
                                      cursor: pointer;
                                    "
                                    v-on:click="goDataset(item)"
                                  >
                                    ID: {{ item["Dataset ID"] }}
                                  </div>
                                </div>
                              </div>
                            </v-list-item-title>

                            <div style="font-size: 0.85rem; margin-top: 5px">
                              Descripción:
                            </div>
                            <v-list-item-subtitle
                              style="
                                white-space: initial;
                                background: whitesmoke;
                                padding: 10px;
                                color: black;
                              "
                              v-html="
                                item.Summary.split('cdm_data_type')[0]
                                  .replaceAll('\\n', ' <br> ')
                                  .substring(0, 250)
                                  .replaceAll('\\u00c3\\u00a1', 'á')
                                  .replaceAll('\\u00c3\\u00b1', 'ñ')
                                  .replaceAll('\\u00c3\\u00ad', 'í')
                                  .replaceAll('\\u00c3\\u00a9', 'é')
                                  .replaceAll('\\u00c3\\u00ba', 'ú')
                                  .replaceAll('\\u00c3\\u00b3', 'ó')
                                  .replaceAll('\\ufffd\\ufffd', 'ó')
                              "
                            >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <br />
                        </v-list-item>

                        <v-divider
                          v-if="
                            item.Title !=
                            '* The List of All Active Datasets in this ERDDAP *'
                          "
                        ></v-divider>
                      </div>
                    </template>
                  </v-list>
                  <br />
                  <v-pagination
                    color="#283593"
                    v-model="pagina"
                    :length="espacios"
                  ></v-pagination>
                  <br />
                  <br />
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "Library",
  components: {
    //HomeGraph
  },
  created() {
    this.getData();
    this.getFilters();
    this.keyword_filtro = this.$route.query.keywords;
    this.institucion_filtro = this.$route.query.institutions;
    this.variable_filtro = this.$route.query.variables;

    if (
      this.$route.query.keywords == "oceanos" ||
      this.$route.query.keywords == "criosfera"
    ) {
      this.dialog = true;
    }
    document.title = "Librería - Observatorio de Cambio Climático";
  },
  methods: {
    goDataset: function (name) {
      if (name.griddap != "") {
        this.$router.push("data/griddap/" + name["Dataset ID"]);
      } else if (name.tabledap != "") {
        this.$router.push("data/" + name["Dataset ID"]);
      }
    },
    getData: async function () {
      let base = "";
      if (this.$route.query.keywords) {
        base = base + "?keywords=" + this.$route.query.keywords;
      }
      if (this.$route.query.institutions) {
        if (base == "") {
          base = base + "?institutions=" + this.$route.query.institutions;
        } else {
          base = base + "&institutions=" + this.$route.query.institutions;
        }
      }
      if (this.$route.query.variables) {
        if (base == "") {
          base = base + "?variables=" + this.$route.query.variables;
        } else {
          base = base + "&variables=" + this.$route.query.variables;
        }
      }
      if (this.$route.query.text) {
        if (base == "") {
          base = base + "?text=" + this.$route.query.text;
        } else {
          base = base + "&text=" + this.$route.query.text;
        }
      }
      this.loading = true;
      let datasets;
      try {
        datasets = (await this.axios.get("/erddap/list" + base)).data;
      } catch (error) {
        datasets = {
          rows: [],
          headers: [],
        };
      } finally {
        this.loading = false;
        this.datasets = datasets;
        this.resultados = this.datasets.rows ? this.datasets.rows : [];
      }
    },
    getFilters: async function () {
      this.loading = true;
      let filters;
      try {
        filters = (await this.axios.get("/erddap/filters")).data;
      } catch (error) {
        filters = {
          categorias: [],
          institutions: [],
          variables: [],
        };
      } finally {
        this.loading = false;
        this.filters = filters;
        this.filters.categorias = this.filters.categorias.map((value) => {
          return { text: value, value: false };
        });
        this.filters.institutions = this.filters.institutions.map((value) => {
          return { text: value, value: false };
        });
        this.filters.variables = this.filters.variables.map((value) => {
          return { text: value, value: false };
        });
      }
    },
    addFilter: function (element, type) {
      if (type == "institucion") {
        let index = this.filters_institucion.findIndex(
          (x) => x == element.text
        );
        if (index === -1) {
          this.filters_institucion.push(element.text);
        }
      } else if (type == "variable") {
        let index = this.filters_variables.findIndex((x) => x == element.text);
        if (index === -1) {
          this.filters_variables.push(element.text);
        }
      } else if (type == "keywords") {
        let index = this.filters_keywords.findIndex((x) => x == element.text);
        if (index === -1) {
          this.filters_keywords.push(element.text);
        }
      }
    },
    removeFilter: function (element, type) {
      if (type == "institucion") {
        let index = this.filters_institucion.findIndex(
          (x) => x == element.text
        );
        if (index > -1) {
          this.filters_institucion.splice(index, 1);
        }
      } else if (type == "variable") {
        let index = this.filters_variables.findIndex((x) => x == element.text);
        if (index > -1) {
          this.filters_variables.splice(index, 1);
        }
      } else if (type == "keywords") {
        let index = this.filters_keywords.findIndex((x) => x == element.text);
        if (index > -1) {
          this.filters_keywords.splice(index, 1);
        }
      }
    },
    applyFilters: function () {
      this.$router.push({
        path: "/data",
        query: {
          keywords: this.keyword_filtro,
          institutions: this.institucion_filtro,
          variables: this.variable_filtro,
        },
      });
    },
    irAHome() {
      this.$router.push({
        path: "/",
      });
    },
    realizarBusqueda: function () {
      this.$router.push({
        path: "/data",
        query: {
          keywords: this.keyword_busqueda,
          institutions: this.institution_busqueda,
          variables: this.variable_busqueda,
          text: this.text_busqueda,
        },
      });
    },
    limpiarBusqueda: function () {
      this.keyword_busqueda = "";
      this.variable_busqueda = "";
      this.text_busqueda = "";
      this.institution_busqueda = "";
    },
  },
  data() {
    return {
      busqueda: null,
      pagina: 1,
      loading: false,
      resultados: [],
      cards: [],
      items: [
        { text: "Organización", icon: "mdi-home", value: undefined },
        { text: "Agua", icon: "mdi-clock", value: "agua" },
        { text: "Atmósfera", icon: "mdi-account", value: "atmosfera" },
        { text: "Criósfera", icon: "mdi-flag", value: "criosfera" },
        { text: "Océanos", icon: "mdi-flag", value: "oceanos" },
      ],
      dialog_map: false,
      dialog_visualizacion: false,
      dialog: false,
      cards_banner: [],
      banner_title: "Selecciona una zona en el gráfico",
      banner_subtitle: undefined,
      resultado_consulta: false,
      datasets: {
        rows: [],
        headers: [],
      },
      filters: {
        categorias: [],
        institutions: [],
        variables: [],
      },
      filters_variables: [],
      filters_institucion: [],
      filters_keywords: [],
      keywords: 0,
      instituciones: 0,
      variables: 0,
      institutions: 0,
      avanzada: false,
      text_busqueda: "",
      institution_busqueda: "",
      variable_busqueda: "",
      keyword_busqueda: "",
      institucion_filtro: null,
      keyword_filtro: null,
      variable_filtro: null,
    };
  },
  watch: {
    "$route.params"() {
      window.location.reload();
    },
  },
  computed: {
    resultadoPagina: function () {
      return this.resultados.slice(
        10 * (this.pagina - 1),
        10 * (this.pagina - 1) + 10
      );
    },
    espacios: function () {
      if (
        Math.floor(this.resultados.length / 10) ==
        this.resultados.length / 10
      ) {
        return this.resultados.length / 10;
      } else {
        return Math.floor(this.resultados.length / 10) + 1;
      }
    },
    keywordsActivas: function () {
      let max = this.filters.categorias.length;
      return this.filters.categorias.slice(
        0,
        this.keywords * 5 + 5 > max ? max : this.keywords * 5 + 5
      );
    },
    variablesActivas: function () {
      let max = this.filters.variables.length;
      return this.filters.variables.slice(
        0,
        this.variables * 5 + 5 > max ? max : this.variables * 5 + 5
      );
    },
    institucionesActivas: function () {
      let max = this.filters.institutions.length;
      return this.filters.institutions.slice(
        0,
        this.institutions * 5 + 5 > max ? max : this.institutions * 5 + 5
      );
    },
    arrayInstituciones: function () {
      return this.filters.institutions.map(function (x) {
        return x.text;
      });
    },
     arrayCategorias: function () {
      return this.filters.categorias.map(function (x) {
        return x.text;
      });
    },
    arrayVariables: function () {
      return this.filters.variables.map(function (x) {
        return x.text;
      });
    },
  },
};
</script>

<style>
.indigo.darken-3 {
  background-color: #283593 !important;
  border-color: #283593 !important;
  color: white;
}
.darken-3--text {
  color: #283593 !important;
}

.btn-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f8f8f8;
}

.btn-container {
  height: 40px;
  width: 90%;
  margin: 8px 0;
}

.btn-default {
  background-color: rgb(0, 0, 153);
  border-color: rgb(0, 0, 153);
  min-height: 48px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  position: relative;
  color: white;
  cursor: pointer;
  border-radius: 48px;
}

.btn-default--none {
  background-color: whitesmoke;
  border-color: rgb(230, 227, 227);
  border-style: solid;
  min-height: 48px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  display: flex;
  position: relative;
  color: black;
  border-width: 1px;
  border-radius: 48px;
}
</style>

