<template>
<div>
    <v-overlay :value="dialog_map"></v-overlay>
    <v-dialog 
      v-model="dialog_map" width="80%">
      <div style="padding: 10px; background: white; " > 
        <v-menu
          bottom
          left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            style="float: right; position: relative"
              v-on:click="dialog_map = false;"
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </template>
        </v-menu>
        <h2>Explorar mapa </h2>
        <div class="embed-container"><iframe width="500" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="Observatorio de Cambio Climatico" src="//www.arcgis.com/apps/Embed/index.html?webmap=e3b3db11cc5f49f2815472ee4e68ebfd&extent=-123.2446,-47.8919,-26.2134,-1.0841&zoom=true&previewImage=false&scale=false&legend=true&disable_scroll=true"></iframe></div>       
      </div>
    </v-dialog>

    <v-overlay :value="dialog_visualizacion"></v-overlay>
     <v-dialog 
      v-model="dialog_visualizacion"  width="800px">
      <div style="padding: 10px; background: #e3e3e3; border-radius: 10px;" > 
        <div style="background: white;border-radius: 10px; padding: 20px; " > 
        <v-menu
          bottom
          left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            style="float: right; position: relative"
              v-on:click="dialog_visualizacion = false;"
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </template>
        </v-menu>
        <br>
        <div> 
            <v-carousel
              :show-arrows="false"
              delimiter-icon="mdi-minus">
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i">
                <v-row>
                  <v-col style="text-align: left">
                    <br>
                    <h2> Titulo visualizacion </h2>
                    <br>
                    <b> Descripcion: </b>
                    <br>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi eius totam, fugit veritatis architecto quas! Ullam sequi doloribus, inventore beatae consequuntur ea molestiae atque temporibus, velit reiciendis, soluta alias debitis!
                    <br>
                    <br>
                    <b>Fuente:</b> 
                    <br>
                    Producto 43
                    <br>
                    <br>
                    <b>Última actualización:</b> 
                    <br>
                    Producto 43
                  </v-col>
                  <v-col >
                    <iframe src='https://flo.uri.sh/story/995952/embed' title='Interactive or visual content' class='flourish-embed-iframe' frameborder='0' scrolling='no' style='width:100%;height:450px;' sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe><div style='width:100%!;margin-top:4px!important;text-align:right!important;'><a class='flourish-credit' href='https://public.flourish.studio/story/995952/?utm_source=embed&utm_campaign=story/995952' target='_top' style='text-decoration:none!important'><img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style='width:105px!important;height:16px!important;border:none!important;margin:0!important;'> </a></div>
                  </v-col>
                </v-row>
           </v-carousel-item>
            </v-carousel>
           </div>
        </div>
        <br>
        
      </div>
    </v-dialog>

  <div class="home-header" style="width: 100%; height: 300px; background-size: cover; background-image: url('https://www.minciencia.gob.cl/uploads/filer_public_thumbnails/filer_public/23/27/23270f20-e863-495d-b376-a165566df2c0/whatsapp_image_2021-08-12_at_42325_pm_1.jpeg__1280x854_q85_subsampling-2.jpg')"> 
    <div>
    <p>  Bienvenido al <br><span style="font-size: 25px">Observatorio de <b>Cambio Climático</b></span></p>
    <br>
     <v-row >  
       <v-col>
        <v-btn outlined style="color: white" v-on:click="dialog_map= true" >
          <v-icon left>
            mdi-earth
          </v-icon>
          Explorar mapa
        </v-btn>
      </v-col>
      <v-col>
        <v-btn outlined style="color: white" v-on:click="dialog_visualizacion= true;"  >
          <v-icon left>
            mdi-chart-areaspline
          </v-icon>
          Visualizaciones
        </v-btn>
      </v-col>
    </v-row>
    </div>
  </div>
  <div class="content-home" >
    <v-overlay :value="loading">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
      <v-row> 
        <v-col cols="2" class="panel-izq" style="background: white;">
          <div>
          <v-list dense>
              <v-subheader>CATEGORÍAS</v-subheader>
              <v-list-item-group
                v-model="section"
                color="primary"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>  
            </div>
        </v-col>
        <v-col cols="10" class="panel-der">
      <div class="banner" style="background: white;"> 
       
        <h1>Comparte tu trabajo </h1>
        <div v-if="items[this.section].value"> 
          <br>
           <h2> {{ items[this.section].text }} </h2>
        </div>
        <br>

        <h3> Temperatura en los últimos 5 años en Santiago </h3>
         <v-row>
           
            <v-col  cols="12"
            sm="3"
            md="4">
             <v-avatar color="black">
              <v-icon >
                mdi-account-circle
              </v-icon>
            </v-avatar>
            Ignacio Acuña
              <div style="height: 250px">
                <img alt="kernel active image" src="https://www.kaggleusercontent.com/kf/75633743/eyJhbGciOiJkaXIiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2In0..mQRLh2JQVtIJ65-7L5SbRg.2XL7PBVKbOaw6Q3hJP63nMmc_JSgbdEC34hhuqhpN0jRPUp2lLvk38CRWAoLucWdZEYnilOXX66GIifZ4hrObcFeohX2kppKw3-cuLxh59VNZkY0Cp_xsewxmon1A_7W9CYshV29DOgs8PsUrg9PnU-zjR8GqeTC81ld8I_6_7g_k3PHQ5AQbW20Erk_LhUREVHWuoCRgaX0q82yj50NF0CdvUOYNffN6iFwYovCvSh8nZRhhVIx-_24D35DrG8O-RyHPdKteLrPE-pqCOF93sg9bgS2ApKZhGRCMG2JgeKAIp7kYUV5CprVnhhNxzQ3Bt7CDQYZ4GSnJ4jSKO2gVkxTPZ044qPY9laTZAPp3Oz-xKWM81UI5xLsi9SY26DBZekFbycOqnh_46PxKDBXQ0yfcj8g1Lxmu7SXTIzuH7qiD4D531vJohwxDQxvrPzAdIpuparHnQ8rdzRJEAcLDIFRcHNugPot9aZAVwjyMCuIcP5aEp7hfnKEKYwvkdZVg7Ifiz61tBW2oIZJhg6s4JZen82ICBM_pIzDPDfqyHT3sWBXq26KW3FaJyBZXlvQF0FEK0jE8-4nZSVajxEMEHgbuRtUujV9afCGfAm2cr4kiq3I0KJGb6qjLKnC7zLMXN0Ifun49nrVXMeWRcwJDKBLkm02pIR6RqCqMp-StgQ.Uwv4DoGE0ofy4QvYnKHPKQ/__results___files/__results___10_1.png" class="NewsfeedKernel_KernelContentActiveImage--1gv9w3g kTDhD" style="align-self: center;">
              </div>
            </v-col>
            <v-col style="padding: 30px">
              <b> Descripción </b> <br>
              El presente analisis representa la temperatura promedio de los últimos 5 años en la región metropolitana, usando una predicción simple mediante una regresión lineal simple.
              Los archivos utilizados fueron "Calidad del aire por hora".
              <br>
              <br>
              <b> Reproducir: </b>
              <br> <br>
              <div style="
                  background-color: #e3e3e3;
                  padding: 10px 
              "> import pandas as pd
               <br> 
               temp = pd.read_csv("temperatura.csv")
               <br><br>
               temp.histogram()
               <br>
               templ.plot()
               </div>
            </v-col>
         </v-row>
         <v-divider></v-divider>
         <br>
         <br>
         <h3> Humedad en los últimos 5 años en Punta Arenas </h3>
         <v-row>
           
            <v-col  cols="12"
            sm="3"
            md="4">
             <v-avatar color="black">
              <v-icon >
                mdi-account-circle
              </v-icon>
            </v-avatar>
            Ignacio Acuña
              <div style="height: 250px">
<img alt="kernel active image" src="https://www.kaggleusercontent.com/kf/75642275/eyJhbGciOiJkaXIiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2In0..H3qFvoc-Uk7jZR4OCmbFCA.lWAk4jSzH7mmnNUadkDruW8iT8Sl5ZiGE3iwVra-8ipzgltV6yNj_04p6P3U7HEufBw7IRtRBXV02w5Y06UZcMIxtnor6w_jiu17R1a9_kvXsb_AWi3W4DBXG_ZfG-ohbmtzUTm0aeU1NelWHb-bHQfsNfHXWzT7YQvkcag7Vob8IhUeJ6fR5w9WMq09qYhfmYcC9NxdTkk-jW2ueacYU6OBIjJo4qR58FffEPnp3qojXF05z6ektacKf-Wcz4ukIqL_CuOYHjza-UqxIxhMxclwhmO31fDxiew2VabqsbDKaheHzVS09FfXXqILf363_lPjcficAzdNs7aZrvRzuxIElcMe-pox60Igh3h9fBFhtib4vZh5T4eDLcLsTpTiLkKqnf6nK7H8Kqfd3Qbr4pHwtSU06oKPJ26QupHRvcf0t58_f04Jbsski1x5xu5TwK3jLp0mELQzbU6iEltyN6pAs_AQCnMgpZxjSYxXu796f8eOFovdwfUbq7KJkWAKFdOJJvhWMDduMwSea31ojMCwTioPjfCo5VMM25mnABttZdcryYgHhJtmj-Gqv_XKb2VSy65e95zXkq2jKIxXS-WkauTY_mHNcTq2mhG-MIIYEEQe8oaDgo6xSVodzGTOqcZa9tT8sQwVW8LgqCczMwd-V9wzLG2N-fmZx7LoL-Q.zUoMttAVdQTzzPP4FBKm2A/__results___files/__results___15_0.png" class="NewsfeedKernel_KernelContentActiveImage--1gv9w3g kTDhD" style="align-self: center; width: 100%">              </div>
            </v-col>
            <v-col style="padding: 30px">
              <b> Descripción </b> <br>
              El presente analisis representa la temperatura promedio de los últimos 5 años en la región metropolitana, usando una predicción simple mediante una regresión lineal simple.
              Los archivos utilizados fueron "Calidad del aire por hora".
              <br>
              <br>
              <b> Reproducir: </b>
              <br> <br>
              <div style="
                  background-color: #e3e3e3;
                  padding: 10px 
              "> import pandas as pd
               <br> 
               temp = pd.read_csv("temperatura.csv")
               <br><br>
               temp.histogram()
               <br>
               templ.plot()
               </div>
            </v-col>
         </v-row>
         <v-divider></v-divider>
          <br> <br>
          <v-pagination
            color="#1966c0"
            v-model="pagina"
            :length="espacios"
            :total-visible="6"
          ></v-pagination>
      </div>
    </v-col>
      </v-row>
    </div>
    
    
  </div>
</template>

<script>

export default {
  name: 'Share',
  components: {
  },
  created() {
      this.getData()
  },
  methods: {
    goDataset: function(name){
      this.$router.push('dataset/' + name)
    },
    getData: async function () {
      this.loading = true
      let cards;
      try {
        cards = (await this.axios.get( "/dataset", {
            params: {
              category: this.section ? this.items[this.section].value : undefined,
            }
          })).data
      } catch (error) {
        cards = []
      } finally {
        this.cards = cards
        this.loading = false
      }
    }
  },
  data () {
      return {
        busqueda: null,
        pagina: 1,
        loading: false,
        cards: [
        ],
        items: [
        { text: 'Inicio', icon: 'mdi-home', value: undefined },
        { text: 'Destacados', icon: 'mdi-clock', value: "agua"},
        { text: 'Más populares', icon: 'mdi-clock', value: "agua"},
        { text: 'Más recientes', icon: 'mdi-account', value: "atmosfera" },
      ],
      slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
        section: 0,
        dialog_map: false,
        dialog_visualizacion: false
      }
    },
    computed: {
      resultadoBusqueda: function(){
        if(this.busqueda == null){
          return this.cards
        }
        else {
          return this.cards.filter(card => card.titulo.toUpperCase().includes(this.busqueda.toUpperCase()) || (card.descripcion.toUpperCase().includes(this.busqueda.toUpperCase()) ))
        }
      },
      datasetsHome: function(){
         return this.resultadoBusqueda.slice(
                6 * (this.pagina - 1),
                6 * (this.pagina - 1) + 6
            );
      },
      espacios: function(){
        if (Math.floor(this.resultadoBusqueda.length / 6) == (this.resultadoBusqueda.length / 6) ){
          return this.resultadoBusqueda.length / 6
        }
        else {
          return Math.floor(this.resultadoBusqueda.length / 6) + 1
        }
      }

    },
    watch: {
      section: function(){
        this.getData()
      }

    }
}
</script>

<style>


@media only screen and (max-width: 600px) {
  .content {
    padding: 0px 30px 0px 30px;
    margin-right: 0px;
  }
  .panel-izq{
    display: none;
  }
  .panel-der{
    max-width: unset;
    min-width: 100%;
  }
  
}





.embed-container {position: relative; padding-bottom: 40%; height: 0; max-width: 100%;} .embed-container iframe, .embed-container object, .embed-container iframe{position: absolute; top: 0; left: 0; width: 100%; height: 100%;} small{position: absolute; z-index: 40; bottom: 0; margin-bottom: -15px;}

.home-header{
  text-align: center;
  padding: 0 20px; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}


.panel-izq{
  border: 10px rgb(86, 54, 54);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px;
  margin-top: 10px;
}

</style>
