<template>
  <div>
    <div class="home-header" style="width: 100%; height: 400px">
      <div class="fitted" style="text-align: left" v-if="!resultado_consulta">
        <div style="margin: auto"></div>
        <br />
        <p>
          Bienvenido a la primera versión del <br />

          <img
            src="images/OCC_color-blanco.png"
            alt="Dirección Meteorologica"
            style="max-width: 400px"
            class="foto_banner"
          />
        </p>
        Proyecto que busca potenciar a Chile como un sensor global para el
        cambio climático. Es liderado por el Ministerio de Ciencia y en
        colaboración con los ministerios de Relaciones Exteriores y el de Medio
        Ambiente.
        <br />
        <br />
        <br />
        <!--
        <v-btn outlined style="color: white" v-on:click="dialog_map = true" disabled>
          <v-icon left> mdi-earth </v-icon>
          Explorar mapa
        </v-btn> -->
        <v-btn
          outlined
          style="color: white; margin-left: 10px"
          v-on:click="goLibreria()"
        >
          <v-icon left> mdi-book-open-blank-variant </v-icon>
          Explorar datos
        </v-btn>
      </div>
      <div v-else style="width: 400px">
        La temperatura promedio de tu región en los últimos 10 años fue de Lorem
        ipsum dolor sit amet consectetur adipisicing elit. Hic tempore enim
        fugiat minus quisquam unde deleniti quidem, provident atque pariatur
        reiciendis repellendus laborum. Temporibus autem, nemo commodi
        repellendus sapiente facere?
        <br />
        <br />
        <v-btn
          outlined
          style="color: white"
          v-on:click="resultado_consulta = false"
        >
          Volver
        </v-btn>
      </div>
    </div>

    <div class="banner-home">
      <div style="margin: auto; width: 700px">
        <v-row align="center" justify="space-around">
          <div
            style="
              padding: 30px;
              padding: 30px;
              border-right-style: solid;
              margin-top: 10px;
            "
          >
            SELECCIONA UNA CATEGORÍA
          </div>
          <v-col>
            <div class="btn-ctgry-hm" v-on:click="irA('agua')">
              <v-icon x-large color="white"> mdi-water</v-icon>
              <div>Agua</div>
            </div>
          </v-col>
          <v-col>
            <div class="btn-ctgry-hm" v-on:click="irA('oceanos')">
              <v-icon x-large color="white"> mdi-waves</v-icon>
              <div>Océano</div>
            </div>
          </v-col>
          <v-col>
            <div class="btn-ctgry-hm" v-on:click="irA('atmosfera')">
              <v-icon x-large color="white"> mdi-weather-windy</v-icon>
              <div>Atmósfera</div>
            </div>
          </v-col>
          <v-col>
            <div class="btn-ctgry-hm" v-on:click="irA('criosfera')">
              <v-icon x-large color="white"> mdi-image-filter-hdr</v-icon>
              <div>Criósfera</div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-divider></v-divider>
    <div style="position: relative">
      <img
        src="https://www.minciencia.gob.cl/static/images/footer-detail-left.svg"
        style="position: absolute; left: 0; top: 100px"
      />
      <img
        src="https://www.minciencia.gob.cl/static/images/footer-detail-right.svg"
        style="position: absolute; right: 0; bottom: 0"
      />
      <div style="padding: 30px; text-align: left" class="fitted">
        <h2>¿Qué es el Observatorio de Cambio Climático?</h2>
        <br />
        <v-row>
          <v-col cols="12" sm="4">
            <v-card height="500" class="mx-auto">
              <v-img max-height="250" src="images/sensores.png"></v-img>
              <br />
              <div style="padding-left: 10px">
                <b> Red de sensores </b> <br />
                <div style="font-size: small">
                  El OCC consolida una red integrada de sensores y datos
                  distribuidos en el territorio nacional, provenientes de
                  instituciones públicas, con una robusta y adecuada densidad,
                  distribución territorial y variedad de variables e
                  instrumentos de observación del entorno.
                </div>
              </div>

              <br />
              <br />
            </v-card>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card height="500" class="mx-auto">
              <v-img max-height="250" src="images/datos.png"></v-img>
              <br />
              <div style="padding-left: 10px">
                <b> Datos </b> <br />
                <div style="font-size: small">
                  Se disponibilizan datos de manera abierta y estandarizada para
                  contribuir a la exploración, vigilancia, investigación, y la
                  toma de decisiones basadas en evidencia científica. Contiene
                  datos de variables de aguas, oceános, atmosfera y criósfera.
                  Las categorias y las variables que las componen están en
                  constante evolución y complemento; y responden a las
                  priorizaciones y recomendaciones de la comunidad científica
                  que las utiliza.
                </div>
              </div>

              <br />
              <br />
            </v-card>
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card height="500" class="mx-auto">
              <v-img max-height="250" src="images/gov.png"></v-img>
              <br />

              <div style="padding-left: 10px">
                <b> Gobernanza</b>
                <div style="font-size: small">
                  Se instalará la gobernanza del OCC en el Ministerio de
                  Ciencia, Tecnología, Conocimiento e Innovación, que dirigirá,
                  coordinará y facilitará colaboraciones entre la comunidad
                  científica, la ciudadanía, el sector público y el sector
                  privado, y establecerá estándares de interoperabilidad de los
                  datos
                </div>
              </div>

              <br />
              <br />
            </v-card>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="content-home">
      <br />
      <v-row class="fitted"> </v-row>

      <br />
      <br />
      <div class="section-header fitted" style="text-align: left">
        <img
          src="https://www.minciencia.gob.cl/uploads/Icon/2021-08-26/IDXPVSNLQAE8W5O5JX6MVKTYC7273R/news-01_1.svg"
          alt=""
          aria-hidden="true"
          class="section-icon d-block a11y-contrast-d-none"
        />
        <h2 class="section-title with-line">
          Iniciativas de visualización de datos
        </h2>
      </div>
      <br />
      <v-row class="fitted">
        <v-col cols="12" sm="4">
          <v-card class="mx-auto">
            <v-img src="images/GIS-IFOP.jpeg" height="100px"> </v-img>
            <v-card-title
              style="
                word-break: normal;
                font-size: medium;
                text-align: left;
                font-weight: 600;
                height: 100px;
              "
            >
              <a href="https://www.ifop.cl/red-de-monitoreo-cambio-climatico/" style="text-decoration: none; color: black"> IFOP </a>
            </v-card-title>
            <v-card-subtitle style="text-align: left">
              <a href="https://www.ifop.cl/red-de-monitoreo-cambio-climatico/" style="text-decoration: none; color: black">
                Sistema de Información Interoperable para seguimiento del Cambio
                Climático</a
              >
            </v-card-subtitle>
          </v-card>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <br />
      <br />

      <div class="section-header fitted" style="text-align: left">
        <img
          src="https://www.minciencia.gob.cl/uploads/Icon/2021-08-26/IDXPVSNLQAE8W5O5JX6MVKTYC7273R/news-01_1.svg"
          alt=""
          aria-hidden="true"
          class="section-icon d-block a11y-contrast-d-none"
        />
        <h2 class="section-title with-line">Categorías de datos</h2>
      </div>
      <br />
      <v-row class="fitted">
        <v-col v-for="(card, i) in datasetsHome" :key="i" cols="12" sm="4">
          <v-card class="mx-auto">
            <v-img :src="card.imagen" height="100px"> </v-img>
            <v-card-title
              style="
                word-break: normal;
                font-size: medium;
                text-align: left;
                font-weight: 600;
                height: 100px;
              "
            >
              {{ card.nombre }}
            </v-card-title>
            <v-card-subtitle style="text-align: left">
              {{ card.descripcion.substring(0, 200) }}
            </v-card-subtitle>
            <v-card-actions>
              <v-btn
                color="orange lighten-2"
                @click="
                  card.show ? (card.show = !card.show) : (card.show = true);
                  render();
                "
                text
              >
                {{ card.show ? "OCULTAR" : "VER" }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="
                  card.show ? (card.show = !card.show) : (card.show = true);
                  render();
                "
              >
                <v-icon>{{
                  card.show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-if="card.show">
                <v-divider></v-divider>
                <v-card-text>
                  <v-list-item
                    two-line
                    v-for="(data, i) in card.datasets"
                    :key="i"
                  >
                    <v-list-item-icon style="margin-right: 10px">
                      <v-avatar size="36">
                        <img :src="data.dataset_imagen" />
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="white-space: initial; cursor: pointer"
                        v-on:click="goDataset(data.dataset_id)"
                      >
                        <b>{{ data.dataset_nombre }} </b></v-list-item-title
                      >
                      <v-list-item-subtitle style="white-space: initial">{{
                        data.dataset_autor
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
          <v-spacer></v-spacer>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card class="mx-auto">
            <v-img src="images/satelite.jpg" height="100px"> </v-img>
            <v-card-title
              style="
                word-break: normal;
                font-size: medium;
                text-align: left;
                font-weight: 600;
                height: 100px;
              "
            >
              Datos Satelitales
            </v-card-title>
            <v-card-subtitle style="text-align: left">
              Conjunto de datos obtenidos a través de mediciones de los
              satélites Sentinel de la Constelación Copernicus
            </v-card-subtitle>
            <v-card-actions>
              <v-btn
                color="orange lighten-2"
                @click="
                  satelite.show
                    ? (satelite.show = !satelite.show)
                    : (satelite.show = true);
                  render();
                "
                text
              >
                {{ satelite.show ? "OCULTAR" : "VER" }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="
                  satelite.show
                    ? (satelite.show = !satelite.show)
                    : (satelite.show = true);
                  render();
                "
              >
                <v-icon>{{
                  satelite.show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-if="satelite.show">
                <v-divider></v-divider>
                <v-card-text>
                  <v-list-item
                    two-line
                    v-for="(data, i) in satelite.datasets"
                    :key="i"
                  >
                    <v-list-item-icon style="margin-right: 10px">
                      <v-avatar size="36">
                        <img :src="data.dataset_imagen" />
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        style="white-space: initial; cursor: pointer"
                      >
                        <a
                          style="text-decoration: none; color: black"
                          target="_blank"
                          href="http://www.datoscopernicus.cl/login"
                        >
                          <b>{{ data.dataset_nombre }} </b>
                        </a></v-list-item-title
                      >
                      <v-list-item-subtitle style="white-space: initial">{{
                        data.dataset_autor
                      }}</v-list-item-subtitle>
                      <span style="font-size: 11px; margin-top: 5px">
                        *Link de ingreso a la plataforma CMM Copernicus
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <br />
      <br />
      <br />
      <div class="section-header fitted" style="text-align: left">
        <img
          src="https://www.minciencia.gob.cl/uploads/Icon/2021-08-26/IDXPVSNLQAE8W5O5JX6MVKTYC7273R/news-01_1.svg"
          alt=""
          aria-hidden="true"
          class="section-icon d-block a11y-contrast-d-none"
        />
        <h2 class="section-title with-line">Instituciones</h2>
        <br />
        <br />
      </div>
      <v-row class="fitted">
        <v-col>
          <a href="https://www.inach.cl/inach/" target="_blank"
            ><img src="images/INACH.png" alt="INACH" style="max-width: 250px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://climatologia.meteochile.gob.cl/" target="_blank"
            ><img
              src="images/DMC.png"
              alt="Dirección Meteorologica"
              style="max-width: 100px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://sinca.mma.gob.cl/" target="_blank"
            ><img
              src="images/MMA.png"
              alt="Ministerio de Medio Ambiente"
              style="max-width: 100px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://minrel.gob.cl/" target="_blank"
            ><img
              src="images/MINREL.png"
              alt="Ministerio de Relaciones Exteriores"
              style="max-width: 100px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://dga.mop.gob.cl/" target="_blank"
            ><img src="images/DGA.jpeg" alt="DGA" style="max-width: 100px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="http://www.ceaza.cl/" target="_blank"
            ><img
              src="images/CEAZA_banner.png"
              alt="CEAZA"
              style="max-width: 250px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://www.ifop.cl/" target="_blank"
            ><img src="images/ifop.png" alt="IFOP" style="max-width: 150px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://ieb-chile.cl/" target="_blank"
            ><img src="images/ieb.png" alt="IEB" style="max-width: 150px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <a href="https://portal.sma.gob.cl/" target="_blank"
            ><img src="images/SMA.png" alt="SMA" style="max-width: 150px"
          /></a>
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    this.getData();
  },
  methods: {
    goDataset: function (name) {
      this.$router.push("data/" + name);
    },
    goLibreria: function () {
      this.$router.push("data");
    },
    getDatasetsBanner: async function (categoria) {
      this.loading = true;
      let cards;
      try {
        cards = (
          await this.axios.get("/dataset", {
            params: {
              category: categoria,
            },
          })
        ).data;
      } catch (error) {
        cards = [];
      } finally {
        this.cards_banner = cards;
        this.loading = false;
      }
    },
    getData: async function () {
      this.loading = true;
      let cards;
      try {
        cards = (
          await this.axios.get("/categorias", {
            params: {
              category: this.section
                ? this.items[this.section].value
                : undefined,
            },
          })
        ).data;
      } catch (error) {
        cards = [];
      } finally {
        this.loading = false;
        this.cards = cards;
      }
    },
    cambiarCiclo: function (value) {
      this.getDatasetsBanner(value);
      this.banner_title = value.charAt(0).toUpperCase() + value.slice(1);
    },
    render() {
      this.$forceUpdate();
    },
    irA(variable) {
      this.$router.push({
        path: "/data",
        query: {
          keywords: variable,
        },
      });
    },
  },
  data() {
    return {
      busqueda: null,
      pagina: 1,
      show: [],
      loading: false,
      cards: [],
      items: [
        { text: "Inicio", icon: "mdi-home", value: undefined },
        { text: "Agua", icon: "mdi-clock", value: "agua" },
        { text: "Atmósfera", icon: "mdi-account", value: "atmosfera" },
        { text: "Criósfera", icon: "mdi-flag", value: "criosfera" },
        { text: "Océanos", icon: "mdi-flag", value: "oceanos" },
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      section: 0,
      dialog_map: false,
      dialog_visualizacion: false,
      cards_banner: [],
      banner_title: "Selecciona una zona en el gráfico",
      banner_subtitle: undefined,
      resultado_consulta: false,
      graphs: [
        { name: "Emisiones de carbono (2021)" },
        { name: "Emisiones de carbono (2021)" },
        { name: "Emisiones de carbono (2021)" },
      ],
      satelite: {
        show: false,
        datasets: [
          {
            dataset_nombre: "Copernicus *",
            dataset_imagen: "images/CMM.png",
            dataset_autor: "Centro de Modelamiento Matemático",
          },
        ],
      },
    };
  },
  computed: {
    resultadoBusqueda: function () {
      if (this.busqueda == null) {
        return this.cards;
      } else {
        return this.cards.filter(
          (card) =>
            card.titulo.toUpperCase().includes(this.busqueda.toUpperCase()) ||
            card.descripcion.toUpperCase().includes(this.busqueda.toUpperCase())
        );
      }
    },
    datasetsHome: function () {
      return this.resultadoBusqueda.slice(
        6 * (this.pagina - 1),
        6 * (this.pagina - 1) + 6
      );
    },
    espacios: function () {
      if (
        Math.floor(this.resultadoBusqueda.length / 6) ==
        this.resultadoBusqueda.length / 6
      ) {
        return this.resultadoBusqueda.length / 6;
      } else {
        return Math.floor(this.resultadoBusqueda.length / 6) + 1;
      }
    },
  },
  watch: {
    section: function () {
      this.getData();
    },
  },
};
</script>

<style>
.content-home {
  padding: 0px 00px 0px 0px;
  min-height: 400px;
  background: whitesmoke;
}

@media only screen and (max-width: 700px) {
  .content {
    padding: 0px 30px 0px 30px;
    margin-right: 0px;
  }
  .panel-izq {
    display: none;
  }
  .panel-der {
    max-width: unset;
    min-width: 100%;
  }
  .banner {
    margin-right: 0px !important;
  }
  .banner-home {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .fitted {
    margin: 0px !important;
  }
  .foto_banner {
    max-width: 200px !important;
  }
}

.banner {
  min-height: 300px;
  border-radius: 5px;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background: white;
  padding: 20px;
  border: 10px;
  border-radius: 10px;
  border-color: #563636;
}

.embed-container {
  position: relative;
  padding-bottom: 40%;
  height: 0;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
small {
  position: absolute;
  z-index: 40;
  bottom: 0;
  margin-bottom: -15px;
}

.home-header {
  text-align: center;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-size: cover !important;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.minciencia.gob.cl/uploads/filer_public_thumbnails/filer_public/23/27/23270f20-e863-495d-b376-a165566df2c0/whatsapp_image_2021-08-12_at_42325_pm_1.jpeg__1280x854_q85_subsampling-2.jpg");
}

.panel-izq {
  border: 10px rgb(86, 54, 54);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 0px 1px;
  margin-top: 10px;
}

.fitted {
  margin-left: 200px;
  margin-right: 200px;
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 1.125rem;
}
.section-title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 1.5rem;
  color: #000;
  padding-bottom: 8px;
  font-size: 20px;
}
.section-title.with-line:after {
  position: absolute;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  content: "";
  border: 0.75px solid #0000009c;
}
.section-header .container .section-title:after {
  right: 15px;
  left: 15px;
}
.section-header,
.section-header .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.white--text {
  color: white !important;
}

.btn-ctgry-hm {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: white;
}
.btn-ctgry-hm:hover {
  background: #93baf729;
}
.banner-home {
  width: 100%;
  background-color: #000033;
  min-height: 100px;
  color: white;
}
</style>
