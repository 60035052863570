<template>
  <div class="wiki" v-html="discusion.text"></div>
</template>

<script>
export default {
  name: "Data",
  components: {},
  created() {
    this.getDiscusion();
  },
  data() {
    return {
      discusion: { text: "" },
    };
  },
  props: ["title"],

  methods: {
    getDiscusion: async function () {
      let result;
      try {
        result = (
          await this.axios.get(
            "https://wiki.occ.minciencia.gob.cl/api.php?action=parse&page=" +
              this.title.replaceAll(" ", "_") +
              "&prop=text&formatversion=2&format=json&origin=*"
          )
        ).data;
      } catch (error) {
        console.log(error);
        result = { parse: { text: "" } };
      } finally {
        this.discusion = result.parse;
      }
    },
  },
};
</script>

<style scoped>
.wiki >>> sup,
.wiki >>> sub {
  line-height: 1em;
}
.wiki >>> .mw-body-content h3,
.wiki >>> .mw-body-content h4,
.wiki >>> .mw-body-content h5,
.wiki >>> .mw-body-content h6 {
  margin-bottom: 0.25em;
}
.wiki >>> body.page-Wikipedia_Portada .firstHeading,
.wiki >>> body.page-Wikipedia_Portada .pagetitle,
.wiki >>> body.page-Wikipedia_Portada .subtitle,
.wiki >>> body.page-Wikipedia_Portada #ca-unprotect,
.wiki >>> body.page-Wikipedia_Portada #ca-move,
.wiki >>> body.page-Wikipedia_Portada #ca-delete,
.wiki >>> body.page-Wikipedia_Portada #mytabs,
.wiki >>> body.page-Wikipedia_Portada #tw-BR,
.wiki >>> body.page-Wikipedia_Portada #tw-xfd,
.wiki >>> body.page-Wikipedia_Portada #catlinks,
.wiki >>> body.page-Wikipedia_Portada #footer-info-lastmod,
.wiki >>> body.page-Wikipedia_Portada .error {
  display: none;
}
.wiki >>> .wikidata-link {
  display: none;
}
.wiki >>> body.mw-editable .wikidata-link {
  display: inline;
}
@media print {
  .wiki >>> #bodyContent {
    text-align: justify;
  }
}
.wiki >>> .autoajustar {
  width: 100%;
  max-width: 100%;
}
.wiki >>> .autoajustar img {
  width: 100%;
  height: auto;
}
.wiki >>> ol.references,
.wiki >>> div.listaref,
.wiki >>> .refcomienza {
  font-size: 90%;
  margin-bottom: 0.5em;
}
.wiki >>> div.listaref ol.references {
  font-size: 100%;
  list-style-type: inherit;
}
.wiki >>> ol.references > li:target,
.wiki >>> sup.reference:target,
.wiki >>> span.citation:target,
.wiki >>> cite:target {
  background-color: #def;
}
.wiki >>> sup.reference {
  font-weight: normal;
  font-style: normal;
}
.wiki >>> .citation {
  word-wrap: break-word;
}
@media screen, handheld {
  .wiki >>> .citation .printonly {
    display: none;
  }
}
.wiki >>> .mw-empty-elt {
  display: none;
}
.wiki >>> sup.reference {
  margin-right: 0.6ch;
}
.wiki >>> sup.reference:not(.separada) {
  margin-left: -0.6ch;
}
.wiki >>> .corchete-llamada {
  display: none;
}
.wiki >>> .redirect-in-category,
.wiki >>> .allpagesredirect a {
  color: gray;
}
.wiki >>> .infobox td,
.wiki >>> .infobox th,
.wiki >>> .infobox_v2 td,
.wiki >>> .infobox_v2 th {
  vertical-align: top;
}
.wiki >>> .infobox caption {
  font-size: larger;
  margin-left: inherit;
}
.wiki >>> .infobox_v2,
.wiki >>> .infobox {
  border: 1px solid #b4bbc8;
  background-color: #f9f9f9;
  color: #000;
  margin: 0.5em 0 0.7em 1.2em;
  padding: 0.4em;
  clear: right;
  float: right;
  font-size: 90%;
  line-height: 1.5em;
  width: 22.5em;
}
.wiki >>> .infobox_v2 .cabecera,
.wiki >>> .infobox .cabecera {
  font-size: 140%;
  font-weight: bolder;
  height: 45px;
  line-height: 1.2em;
  text-align: center;
  vertical-align: middle;
}
.wiki >>> .infobox_v2 .media,
.wiki >>> .infobox .media {
  font-weight: bolder;
  height: 35px;
  text-align: center;
  vertical-align: middle;
}
.wiki >>> .infobox.bordered {
  border-collapse: collapse;
}
.wiki >>> .infobox.bordered td,
.wiki >>> .infobox.bordered th {
  border: 1px solid #b4bbc8;
}
.wiki >>> .infobox.bordered .borderless td,
.wiki >>> .infobox.bordered .borderless th {
  border: 0;
}
.wiki >>> .infobox.sisterproject {
  width: 20em;
  font-size: 90%;
}
.wiki >>> .infobox.geography,
.wiki >>> .infobox_v2.geography {
  text-align: left;
  border-collapse: collapse;
  line-height: 1.2em;
  font-size: 90%;
  border: solid 1px #b4bbc8;
}
.wiki >>> .infobox.geography td,
.wiki >>> .infobox.geography th,
.wiki >>> .infobox_v2.geography td,
.wiki >>> .infobox_v2.geography th {
  border-top: solid 1px #b4bbc8;
  padding: 0.4em 0.6em;
}
.wiki >>> .infobox.geography .mergedtoprow td,
.wiki >>> .infobox.geography .mergedtoprow th,
.wiki >>> .infobox_v2.geography .mergedtoprow td,
.wiki >>> .infobox_v2.geography .mergedtoprow th {
  border-top: solid 1px #b4bbc8;
  padding: 0.4em 0.6em 0.2em 0.6em;
}
.wiki >>> .infobox.geography .mergedrow td,
.wiki >>> .infobox.geography .mergedrow th,
.wiki >>> .infobox_v2.geography .mergedrow td,
.wiki >>> .infobox_v2.geography .mergedrow th {
  border: 0;
  padding: 0 0.6em 0.2em 0.6em;
}
.wiki >>> .infobox.geography .mergedbottomrow td,
.wiki >>> .infobox.geography .mergedbottomrow th,
.wiki >>> .infobox_v2.geography .mergedbottomrow td,
.wiki >>> .infobox_v2.geography .mergedbottomrow th {
  border-top: 0;
  border-bottom: solid 1px #b4bbc8;
  padding: 0 0.6em 0.4em 0.6em;
}
.wiki >>> .infobox.geography .maptable td,
.wiki >>> .infobox.geography .maptable th,
.wiki >>> .infobox_v2.geography .maptable td,
.wiki >>> .infobox_v2.geography .maptable th {
  border: 0;
  padding: 0;
}
.wiki >>> .cabecera.default {
}
.wiki >>> .hlist dl,
.wiki >>> .hlist ol,
.wiki >>> .hlist ul {
  margin: 0;
  padding: 0;
}
.wiki >>> .hlist dd,
.wiki >>> .hlist dt,
.wiki >>> .hlist li {
  display: inline;
}
.wiki >>> .hlist.inline,
.wiki >>> .hlist.inline dl,
.wiki >>> .hlist.inline ol,
.wiki >>> .hlist.inline ul,
.wiki >>> .hlist dl dl,
.wiki >>> .hlist dl ol,
.wiki >>> .hlist dl ul,
.wiki >>> .hlist ol dl,
.wiki >>> .hlist ol ol,
.wiki >>> .hlist ol ul,
.wiki >>> .hlist ul dl,
.wiki >>> .hlist ul ol,
.wiki >>> .hlist ul ul {
  display: inline;
}
.wiki >>> .hlist dt:after {
  content: ":";
}
.wiki >>> .hlist dd:after,
.wiki >>> .hlist li:after {
  content: " · ";
  font-weight: bold;
}
.wiki >>> .hlist dd:last-child:after,
.wiki >>> .hlist dt:last-child:after,
.wiki >>> .hlist li:last-child:after {
  content: none;
}
.wiki >>> .hlist dd.hlist-last-child:after,
.wiki >>> .hlist dt.hlist-last-child:after,
.wiki >>> .hlist li.hlist-last-child:after {
  content: none;
}
.wiki >>> .hlist dd dd:first-child:before,
.wiki >>> .hlist dd dt:first-child:before,
.wiki >>> .hlist dd li:first-child:before,
.wiki >>> .hlist dt dd:first-child:before,
.wiki >>> .hlist dt dt:first-child:before,
.wiki >>> .hlist dt li:first-child:before,
.wiki >>> .hlist li dd:first-child:before,
.wiki >>> .hlist li dt:first-child:before,
.wiki >>> .hlist li li:first-child:before {
  content: " (";
  font-weight: normal;
}
.wiki >>> .hlist dd dd:last-child:after,
.wiki >>> .hlist dd dt:last-child:after,
.wiki >>> .hlist dd li:last-child:after,
.wiki >>> .hlist dt dd:last-child:after,
.wiki >>> .hlist dt dt:last-child:after,
.wiki >>> .hlist dt li:last-child:after,
.wiki >>> .hlist li dd:last-child:after,
.wiki >>> .hlist li dt:last-child:after,
.wiki >>> .hlist li li:last-child:after {
  content: ") ";
  font-weight: normal;
}
.wiki >>> .hlist dd li.hlist-last-child:after,
.wiki >>> .hlist dt dd.hlist-last-child:after,
.wiki >>> .hlist dt dt.hlist-last-child:after,
.wiki >>> .hlist dt li.hlist-last-child:after,
.wiki >>> .hlist li dd.hlist-last-child:after,
.wiki >>> .hlist li dt.hlist-last-child:after,
.wiki >>> .hlist li li.hlist-last-child:after {
  content: ") ";
  font-weight: normal;
}
.wiki >>> .hlist ol {
  counter-reset: listitem;
}
.wiki >>> .hlist ol > li {
  counter-increment: listitem;
}
.wiki >>> .hlist ol > li:before {
  content: " " counter(listitem) " ";
  white-space: nowrap;
}
.wiki >>> .hlist dd ol > li:first-child:before,
.wiki >>> .hlist dt ol > li:first-child:before,
.wiki >>> .hlist li ol > li:first-child:before {
  content: " (" counter(listitem) " ";
}
.wiki >>> .plainlist ul {
  line-height: inherit;
  list-style: none none;
  margin: 0;
}
.wiki >>> .plainlist ul li {
  margin-bottom: 3px;
}
.wiki >>> .floatright {
  float: right;
  clear: right;
  position: relative;
  margin: 0.2em 0 0.8em 1.4em;
}
.wiki >>> .floatleft {
  float: left;
  clear: left;
  position: relative;
  margin: 0.2em 1.4em 0.8em 0;
}
.wiki >>> .navbox {
  border: 1px solid #aaa;
  width: 100%;
  margin: auto;
  clear: both;
  font-size: 88%;
  text-align: center;
  padding: 1px;
}
.wiki >>> .navbox-inner,
.wiki >>> .navbox-subgroup {
  width: 100%;
}
.wiki >>> .navbox-group,
.wiki >>> .navbox-title,
.wiki >>> .navbox-abovebelow {
  padding: 0.25em 1em;
  line-height: 1.5em;
  text-align: center;
}
.wiki >>> th.navbox-group {
  white-space: nowrap;
  text-align: right;
}
.wiki >>> .navbox,
.wiki >>> .navbox-subgroup {
  background: #fdfdfd;
}
.wiki >>> .navbox-list {
  line-height: 1.8em;
  border-color: #fdfdfd;
}
.wiki >>> .navbox th,
.wiki >>> .navbox-title {
  background: #ccccff;
}
.wiki >>> .navbox-abovebelow,
.wiki >>> th.navbox-group,
.wiki >>> .navbox-subgroup .navbox-title {
  background: #ddddff;
}
.wiki >>> .navbox-subgroup .navbox-group,
.wiki >>> .navbox-subgroup .navbox-abovebelow {
  background: #e6e6ff;
}
.wiki >>> .navbox-even {
  background: #f7f7f7;
}
.wiki >>> .navbox-odd {
  background: transparent;
}
.wiki >>> table.navbox + table.navbox {
  margin-top: -1px;
}
.wiki >>> .navbox .hlist td dl,
.wiki >>> .navbox .hlist td ol,
.wiki >>> .navbox .hlist td ul,
.wiki >>> .navbox td.hlist dl,
.wiki >>> .navbox td.hlist ol,
.wiki >>> .navbox td.hlist ul {
  padding: 0.125em 0;
}
.wiki >>> ol + table.navbox,
.wiki >>> ul + table.navbox {
  margin-top: 0.5em;
}
.wiki >>> .navbar {
  display: inline;
  font-size: 88%;
  font-weight: normal;
}
.wiki >>> .navbar ul {
  display: inline;
  white-space: nowrap;
}
.wiki >>> .navbar li {
  word-spacing: -0.125em;
}
.wiki >>> .navbar.mini li span {
  font-variant: small-caps;
}
.wiki >>> .infobox .navbar {
  font-size: 100%;
}
.wiki >>> .navbox .navbar {
  display: block;
  font-size: 100%;
}
.wiki >>> .navbox-title .navbar {
  float: left;
  text-align: left;
  margin-right: 0.5em;
  width: 6em;
}
.wiki >>> .collapseButton {
  float: right;
  font-weight: normal;
  margin-left: 0.5em;
  text-align: right;
  width: auto;
}
.wiki >>> .navbox .collapseButton {
  width: 6em;
}
.wiki >>> .mw-collapsible .navbox-title {
  position: relative;
}
.wiki >>> .navbox-title .mw-collapsible-toggle {
  display: block;
  position: absolute;
  right: 0.5em;
  bottom: 0.5ex;
}
.wiki >>> .navbox .mw-collapsible-toggle {
  width: 6em;
}
.wiki >>> #content a[href$=".pdf"].external,
.wiki >>> #content a[href*=".pdf?"].external,
.wiki >>> #content a[href*=".pdf#"].external,
.wiki >>> #content a[href$=".PDF"].external,
.wiki >>> #content a[href*=".PDF?"].external,
.wiki >>> #content a[href*=".PDF#"].external,
.wiki >>> #mw_content a[href$=".pdf"].external,
.wiki >>> #mw_content a[href*=".pdf?"].external,
.wiki >>> #mw_content a[href*=".pdf#"].external,
.wiki >>> #mw_content a[href$=".PDF"].external,
.wiki >>> #mw_content a[href*=".PDF?"].external,
.wiki >>> #mw_content a[href*=".PDF#"].external {
  background: url(//upload.wikimedia.org/wikipedia/commons/2/23/Icons-mini-file_acrobat.gif)
    no-repeat right;
  padding-right: 18px;
}
.wiki >>> .geo-default,
.wiki >>> .geo-dms,
.wiki >>> .geo-dec {
  display: inline;
}
.wiki >>> .geo-nondefault,
.wiki >>> .geo-multi-punct {
  display: none;
}
.wiki >>> .longitude,
.wiki >>> .latitude {
  white-space: nowrap;
}
.wiki >>> .geo {
}
.wiki >>> .citado {
  font-size: 90%;
  background-color: #f9f9f9;
  padding: 5px 10px;
  margin: 1em 4em;
}
.wiki >>> .notice {
  text-align: justify;
  margin: 1em;
  padding: 0.2em;
}
.wiki >>> .sitedir-ltr .mw-dismissable-notice-body {
  margin: 0.5em 2em 0.5em 2em !important;
}
.wiki >>> .para_biblios {
  display: none;
}
.wiki >>> #mw-subcategories,
.wiki >>> #mw-pages,
.wiki >>> #mw-category-media,
.wiki >>> #filehistory,
.wiki >>> #wikiPreview,
.wiki >>> #wikiDiff {
  clear: both;
}
.wiki >>> div.NavFrame {
  margin: 0;
  padding: 4px;
  border: 1px solid #aaa;
  text-align: center;
  border-collapse: collapse;
  font-size: 95%;
}
.wiki >>> div.NavFrame + div.NavFrame {
  border-top-style: none;
  border-top-style: hidden;
}
.wiki >>> .NavPic {
  background-color: #fff;
  margin: 0;
  padding: 2px;
  float: left;
}
.wiki >>> div.NavFrame .NavHead {
  height: 1.6em;
  font-weight: bold;
  background-color: #ccf;
  position: relative;
}
.wiki >>> div.NavFrame p,
.wiki >>> div.NavFrame div.NavContent,
.wiki >>> div.NavFrame div.NavContent p {
  font-size: 100%;
}
.wiki >>> .NavEnd {
  margin: 0;
  padding: 0;
  line-height: 1px;
  clear: both;
}
.wiki >>> .NavToggle {
  position: absolute;
  top: 0;
  right: 3px;
  font-weight: normal;
  font-size: 90%;
}
.wiki >>> .rellink,
.wiki >>> .dablink {
  font-style: italic;
  padding-left: 1.6em;
  margin-bottom: 0.5em;
}
.wiki >>> .rellink i,
.wiki >>> .dablink i {
  font-style: normal;
}
.wiki >>> table.striped tr:nth-child(even) {
  background-color: #f2f3f4;
}
.wiki >>> .wikitable.plainrowheaders th[scope="row"] {
  font-weight: normal;
  text-align: left;
}
.wiki >>> .wikitable td ul,
.wiki >>> .wikitable td ol,
.wiki >>> .wikitable td dl {
  text-align: left;
}
.wiki >>> .wikitable.left,
.wiki >>> .toccolours.left {
  margin: 1em 1.3em 1em 0;
  float: left;
  clear: left;
}
.wiki >>> .wikitable.right,
.wiki >>> .toccolours.right {
  margin: 1em 0 1em 1.3em;
  float: right;
  clear: right;
}
.wiki >>> .wikitable.center,
.wiki >>> .toccolours.center {
  margin-left: auto;
  margin-right: auto;
  width: inherit;
  text-align: inherit;
}
.wiki >>> table.ambox {
  margin: 0 7% 0.8em;
  border-collapse: collapse;
  background-color: #fbfbfb;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: 95%;
}
.wiki >>> table.ambox th.ambox-text,
.wiki >>> table.ambox td.ambox-text {
  padding: 0.25em 0.5em;
  width: 100%;
}
.wiki >>> table.ambox .ambox-image {
  padding: 2px 0 2px 0.5em;
  text-align: center;
}
.wiki >>> table.ambox .ambox-imageright {
  padding: 2px 0.5em 2px 0;
  text-align: center;
}
.wiki >>> .ambox-notice {
  border-top: 5px solid #608ec2;
}
.wiki >>> .ambox-protection {
  border-top: 5px solid #bba;
}
.wiki >>> .ambox-delete,
.wiki >>> .ambox-serious {
  border-top: 5px solid #b22222;
}
.wiki >>> .ambox-content {
  border-top: 5px solid #f28500;
}
.wiki >>> .ambox-style {
  border-top: 5px solid #f4c430;
}
.wiki >>> .ambox-merge {
  border-top: 5px solid #8c08a4;
}
.wiki >>> .ambox-growth {
  border-top: 5px solid #47a30d;
}
.wiki >>> .ambox.ambox-mini {
  float: right;
  clear: right;
  margin: 0 0 0 1em;
  width: 25%;
}
.wiki >>> .messagebox {
  border: 1px solid #aaa;
  background-color: #f9f9f9;
  width: 90%;
  margin: 0 auto 1em auto;
  padding: 0.2em;
}
.wiki >>> .messagebox.merge {
  border: 1px solid #c0b8cc;
  background-color: #f0e5ff;
  text-align: center;
}
.wiki >>> .messagebox.cleanup {
  border: 1px solid #9f9fff;
  background-color: #efefff;
  text-align: center;
}
.wiki >>> .messagebox.standard-talk {
  border: 1px solid #c0c090;
  background-color: #f8eaba;
  margin: 4px auto;
}
.wiki >>> .col1der td:nth-of-type(1),
.wiki >>> .col2der td:nth-of-type(2),
.wiki >>> .col3der td:nth-of-type(3),
.wiki >>> .col4der td:nth-of-type(4),
.wiki >>> .col5der td:nth-of-type(5),
.wiki >>> .col6der td:nth-of-type(6),
.wiki >>> .col7der td:nth-of-type(7),
.wiki >>> .col8der td:nth-of-type(8),
.wiki >>> .col9der td:nth-of-type(9),
.wiki >>> .col10der td:nth-of-type(10),
.wiki >>> .col11der td:nth-of-type(11),
.wiki >>> .col12der td:nth-of-type(12),
.wiki >>> .col13der td:nth-of-type(13),
.wiki >>> .col14der td:nth-of-type(14),
.wiki >>> .col15der td:nth-of-type(15),
.wiki >>> .col16der td:nth-of-type(16),
.wiki >>> .col17der td:nth-of-type(17),
.wiki >>> .col18der td:nth-of-type(18),
.wiki >>> .col19der td:nth-of-type(19),
.wiki >>> .col20der td:nth-of-type(20) {
  text-align: right;
}
.wiki >>> .col1izq td:nth-of-type(1),
.wiki >>> .col2izq td:nth-of-type(2),
.wiki >>> .col3izq td:nth-of-type(3),
.wiki >>> .col4izq td:nth-of-type(4),
.wiki >>> .col5izq td:nth-of-type(5),
.wiki >>> .col6izq td:nth-of-type(6),
.wiki >>> .col7izq td:nth-of-type(7),
.wiki >>> .col8izq td:nth-of-type(8),
.wiki >>> .col9izq td:nth-of-type(9),
.wiki >>> .col10izq td:nth-of-type(10),
.wiki >>> .col11izq td:nth-of-type(11),
.wiki >>> .col12izq td:nth-of-type(12),
.wiki >>> .col13izq td:nth-of-type(13),
.wiki >>> .col14izq td:nth-of-type(14),
.wiki >>> .col15izq td:nth-of-type(15),
.wiki >>> .col16izq td:nth-of-type(16),
.wiki >>> .col17izq td:nth-of-type(17),
.wiki >>> .col18izq td:nth-of-type(18),
.wiki >>> .col19izq td:nth-of-type(19),
.wiki >>> .col20izq td:nth-of-type(20) {
  text-align: left;
}
.wiki >>> .col1cen td:nth-of-type(1),
.wiki >>> .col2cen td:nth-of-type(2),
.wiki >>> .col3cen td:nth-of-type(3),
.wiki >>> .col4cen td:nth-of-type(4),
.wiki >>> .col5cen td:nth-of-type(5),
.wiki >>> .col6cen td:nth-of-type(6),
.wiki >>> .col7cen td:nth-of-type(7),
.wiki >>> .col8cen td:nth-of-type(8),
.wiki >>> .col9cen td:nth-of-type(9),
.wiki >>> .col10cen td:nth-of-type(10),
.wiki >>> .col11cen td:nth-of-type(11),
.wiki >>> .col12cen td:nth-of-type(12),
.wiki >>> .col13cen td:nth-of-type(13),
.wiki >>> .col14cen td:nth-of-type(14),
.wiki >>> .col15cen td:nth-of-type(15),
.wiki >>> .col16cen td:nth-of-type(16),
.wiki >>> .col17cen td:nth-of-type(17),
.wiki >>> .col18cen td:nth-of-type(18),
.wiki >>> .col19cen td:nth-of-type(19),
.wiki >>> .col20cen td:nth-of-type(20) {
  text-align: center;
}
.wiki >>> #content .gallerybox div.thumb {
  background-color: #f9f9f9;
}
.wiki >>> .gallerybox .thumb img {
  background: white
    url(//upload.wikimedia.org/wikipedia/commons/5/5d/Checker-16x16.png) repeat;
}
.wiki >>> .ns-0 .gallerybox .thumb img,
.wiki >>> .ns-2 .gallerybox .thumb img,
.wiki >>> .ns-100 .gallerybox .thumb img,
.wiki >>> .nochecker .gallerybox .thumb img {
  background: white;
}
.wiki >>> .abbr {
  cursor: help;
  border-bottom: 1px dotted #000;
}
.wiki >>> .portal-column-left-50 {
  float: left;
  width: 49%;
}
.wiki >>> .portal-column-right-50 {
  float: right;
  width: 49%;
}
.wiki >>> .portal-column-left-60 {
  float: left;
  width: 59%;
}
.wiki >>> .portal-column-right-60 {
  float: right;
  width: 59%;
}
.wiki >>> .portal-column-left-40 {
  float: left;
  width: 39%;
}
.wiki >>> .portal-column-right-40 {
  float: right;
  width: 39%;
}
.wiki >>> .portal-column-left-66 {
  float: left;
  width: 66%;
}
.wiki >>> .portal-column-right-66 {
  float: right;
  width: 66%;
}
.wiki >>> .portal-column-left-33 {
  float: left;
  width: 33%;
}
.wiki >>> .portal-column-center-33 {
  margin-left: auto;
  margin-right: auto;
}
.wiki >>> .portal-column-right-33 {
  float: right;
  width: 33%;
}
@media only screen and (max-width: 800px) {
  .wiki >>> .portal-column-left-50,
  .wiki >>> .portal-column-right-50,
  .wiki >>> .portal-column-left-60,
  .wiki >>> .portal-column-right-60,
  .wiki >>> .portal-column-left-40,
  .wiki >>> .portal-column-right-40,
  .wiki >>> .portal-column-left-66,
  .wiki >>> .portal-column-right-66,
  .wiki >>> .portal-column-left-33,
  .wiki >>> .portal-column-center-33,
  .wiki >>> .portal-column-right-33 {
    float: inherit;
    width: inherit;
  }
}
.wiki >>> body.ns-2 #contentSub > span.subpages:before,
.wiki >>> .ns-3 #contentSub > span.subpages:before {
  content: " ¡Atención! Este no es un artículo enciclopédico,sino un espacio de trabajo personal. ";
  background: #fe9;
  border: 2px solid #f0c91f;
  display: block;
  text-align: center;
  color: maroon;
  margin-bottom: 2mm;
  line-height: 120%;
}
.wiki >>> .mw-tag-marker {
  font-family: sans-serif;
  font-style: italic;
  font-size: 90%;
  background-color: #ffe599;
}
.wiki >>> .aviso_filtro {
  border: 1px solid #848484;
  margin: 19px 14px 19px 14px;
  padding: 0.8em 1.2em 0.8em 1.2em;
}
.wiki >>> .mw-tag-marker-posible_vandalismo,
.wiki >>> .mw-tag-marker-botspam {
  background-color: #fec29c;
}
.wiki >>> #hideTOCnumbers .tocnumber {
  display: none;
}
.wiki >>> .toclimit-2 .toclevel-1 ul,
.wiki >>> .toclimit-3 .toclevel-2 ul,
.wiki >>> .toclimit-4 .toclevel-3 ul,
.wiki >>> .toclimit-5 .toclevel-4 ul,
.wiki >>> .toclimit-6 .toclevel-5 ul,
.wiki >>> .toclimit-7 .toclevel-6 ul {
  display: none;
}
.wiki >>> .nowrap,
.wiki >>> .nowraplinks a,
.wiki >>> .nowraplinks .selflink,
.wiki >>> .texhtml,
.wiki >>> sup.reference a {
  white-space: nowrap;
}
.wiki >>> #mf-wlm-banner {
  display: none;
}
.wiki >>> .babel-plain .mw-babel-wrapper {
  float: none;
  clear: none;
  margin: 0;
}
.wiki >>> .babel-plain .mw-babel-box {
  margin: 0;
}
.wiki >>> .babel-plain .mw-babel-header,
.wiki >>> .babel-plain .mw-babel-footer {
  display: none;
}
.wiki >>> #specialchars a {
  padding: 0.1em;
  display: inline-block;
  text-align: center;
  min-width: 1em;
}
.wiki >>> .template-documentation {
  clear: both;
  margin: 1em 0 0 0;
  border: 1px dotted #8bcbff;
  background-color: #eef9fa;
  padding: 0.9em;
}
.wiki >>> .extract a.selflink {
  font-weight: normal;
}
.wiki >>> div.columns dl,
.wiki >>> div.columns ol,
.wiki >>> div.columns ul {
  margin-top: 0;
}
.wiki >>> div.topicon {
  position: absolute;
  top: -35px;
  margin-right: 1px;
  display: block !important;
}
.wiki >>> #mw-panel div.portal div.body ul li.destacado {
  background: url(//upload.wikimedia.org/wikipedia/commons/d/d4/Monobook-bullet-star.png)
    no-repeat 0 10%;
  margin-left: -1.25em;
  padding-left: 1.25em;
}
.wiki >>> #mw-panel div.portal div.body ul li.bueno {
  background: url(//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Art%C3%ADculo_bueno.svg/10px-Art%C3%ADculo_bueno.svg.png)
    no-repeat 0 45%;
  margin-left: -1.25em;
  padding-left: 1.25em;
}
.wiki >>> #coordinates {
  display: inline;
  border: none;
  background: none;
  text-align: right;
  text-indent: 0;
  font-size: 0.85em;
  text-transform: none;
  white-space: nowrap;
}
@media print {
  .wiki >>> .ns-0 .ambox,
  .wiki >>> .ns-104 .ambox,
  .wiki >>> .ns-0 .navbox,
  .wiki >>> .ns-104 .navbox,
  .wiki >>> .ns-0 .vertical-navbox,
  .wiki >>> .ns-0 .infobox.sisterproject,
  .wiki >>> .ns-104 .infobox.sisterproject,
  .wiki >>> .ns-0 .dablink,
  .wiki >>> .ns-104 .dablink,
  .wiki >>> .ns-0 .metadata,
  .wiki >>> .ns-104 .metadata,
  .wiki >>> #filetoc,
  .wiki >>> #footer-places,
  .wiki >>> #mobileview,
  .wiki >>> .mw-jump-link,
  .wiki >>> .mw-empty-elt,
  .wiki >>> .editlink,
  .wiki >>> a.NavToggle,
  .wiki >>> span.collapseButton,
  .wiki >>> span.mw-collapsible-toggle,
  .wiki >>> .noprint {
    display: none !important;
  }
  .wiki >>> #content cite a.external.text:after,
  .wiki >>> .nourlexpansion a.external.text:after,
  .wiki >>> .nourlexpansion a.external.autonumber:after {
    display: none !important;
  }
  .wiki >>> #firstHeading {
    margin: 0;
  }
  .wiki >>> #contentSub {
    display: block;
  }
}

.wiki >>> .mw-cite-backlink,
.wiki >>> .cite-accessibility-label {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.wiki >>> .mw-references-columns {
  -webkit-column-width: 30em;
  -moz-column-width: 30em;
  column-width: 30em;
  margin-top: 0.3em;
}
.wiki >>> .mw-parser-output .mw-references-columns .references {
  margin-top: 0;
}
.wiki >>> .mw-references-columns li {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}
.wiki >>> ol.references {
  counter-reset: mw-ref-extends-parent list-item;
}
.wiki >>> ol.references > li {
  counter-increment: mw-ref-extends-parent;
  counter-reset: mw-ref-extends-child;
}
.wiki >>> ol.references .mw-extended-references {
  list-style: none;
  margin-left: 2em;
}
.wiki >>> .mw-extended-references > li:before {
  counter-increment: mw-ref-extends-child;
  content: counter(mw-ref-extends-parent, decimal) "."
    counter(mw-ref-extends-child, decimal) ". ";
}
.wiki >>> sup.reference {
  unicode-bidi: -moz-isolate;
  unicode-bidi: -webkit-isolate;
  unicode-bidi: isolate;
  white-space: nowrap;
  font-weight: normal;
  font-style: normal;
}
.wiki >>> ol.references li:target,
.wiki >>> sup.reference:target {
  background-color: #eaf3ff;
}
.wiki >>> .mw-ext-cite-error {
  font-weight: bold;
  unicode-bidi: embed;
}
.wiki >>> .mw-ext-cite-error code {
  background-color: transparent;
  color: inherit;
  padding: 1px 2px;
}
.wiki >>> .mw-cite-dir-ltr .reference-text {
  direction: ltr;
  unicode-bidi: embed;
}
.wiki >>> .mw-cite-dir-rtl .reference-text {
  direction: rtl;
  unicode-bidi: embed;
}
@media print {
  .wiki >>> .mw-cite-backlink {
    display: none;
  }
}
.wiki >>> #p-lang .uls-settings-trigger {
  background: transparent
    url(/w/extensions/UniversalLanguageSelector/resources/images/cog-sprite.svg?c3fa1)
    no-repeat center top;
  border: 0;
  min-height: 16px;
  min-width: 16px;
  float: right;
  cursor: pointer;
}
.wiki >>> #p-lang .uls-settings-trigger::-moz-focus-inner {
  border: 0;
}
.wiki >>> #p-lang .uls-settings-trigger:focus {
  outline: 1px solid #36c;
}
.wiki >>> .skin-vector #p-lang .uls-settings-trigger {
  margin-top: 8px;
}
.wiki >>> #p-lang .uls-settings-trigger:hover {
  background-position: center -16px;
}
.wiki >>> .client-nojs #ca-ve-edit,
.wiki >>> .client-nojs .mw-editsection-divider,
.wiki >>> .client-nojs .mw-editsection-visualeditor,
.wiki >>> .ve-not-available #ca-ve-edit,
.wiki >>> .ve-not-available .mw-editsection-divider,
.wiki >>> .ve-not-available .mw-editsection-visualeditor {
  display: none;
}
.wiki >>> .client-js .mw-content-ltr .mw-editsection-bracket:first-of-type,
.wiki
  >>> .client-js
  .mw-content-rtl
  .mw-editsection-bracket:not(:first-of-type) {
  margin-right: 0.25em;
  color: #54595d;
}
.wiki >>> .client-js .mw-content-rtl .mw-editsection-bracket:first-of-type,
.wiki
  >>> .client-js
  .mw-content-ltr
  .mw-editsection-bracket:not(:first-of-type) {
  margin-left: 0.25em;
  color: #54595d;
}
.wiki >>> .badge-goodarticle,
.wiki >>> .badge-goodlist,
.wiki >>> .badge-recommendedarticle {
  list-style-image: url(/w/extensions/WikimediaBadges/resources/images/badge-silver-star.png?70a8c);
}
.wiki >>> .badge-featuredarticle,
.wiki >>> .badge-featuredportal,
.wiki >>> .badge-featuredlist {
  list-style-image: url(/w/extensions/WikimediaBadges/resources/images/badge-golden-star.png?ed948);
}
.wiki >>> .badge-problematic {
  list-style-image: url(/w/extensions/WikimediaBadges/resources/images/badge-problematic.png?f3177);
}
.wiki >>> .badge-proofread {
  list-style-image: url(/w/extensions/WikimediaBadges/resources/images/badge-proofread.png?e81f9);
}
.wiki >>> .badge-validated {
  list-style-image: url(/w/extensions/WikimediaBadges/resources/images/badge-validated.png?6232c);
}
.wiki >>> .badge-digitaldocument {
  list-style-image: url(/w/extensions/WikimediaBadges/resources/images/badge-digitaldocument.png?d1c50);
}
.wiki >>> body {
  margin: 0;
}
.wiki >>> main {
  display: block;
}
.wiki >>> hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.wiki >>> abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
@supports (text-decoration: underline dotted) {
  .wiki >>> abbr[title] {
    border-bottom: 0;
    text-decoration: underline dotted;
  }
}
.wiki >>> pre,
.wiki >>> code,
.wiki >>> tt,
.wiki >>> kbd,
.wiki >>> samp {
  font-family: monospace, monospace;
}
.wiki >>> sub,
.wiki >>> sup {
  line-height: 1;
}
.wiki >>> img {
  border: 0;
}
.wiki >>> button,
.wiki >>> input,
.wiki >>> optgroup,
.wiki >>> select,
.wiki >>> textarea {
  margin: 0;
}
.wiki >>> button::-moz-focus-inner,
.wiki >>> [type="button"]::-moz-focus-inner,
.wiki >>> [type="reset"]::-moz-focus-inner,
.wiki >>> [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.wiki >>> legend {
  color: inherit;
  padding: 0;
}
.wiki >>> .firstHeading:before {
  content: url(/static/images/mobile/copyright/wikipedia-wordmark-en.svg);
  display: block;
  height: 18px;
  left: -9999px;
  line-height: 0;
  margin-bottom: 20px;
  position: absolute;
  width: 119px;
}
.wiki >>> div.tright,
.wiki >>> div.floatright,
.wiki >>> table.floatright {
  clear: right;
  float: right;
}
.wiki >>> div.tleft,
.wiki >>> div.floatleft,
.wiki >>> table.floatleft {
  float: left;
  clear: left;
}
.wiki >>> div.floatright,
.wiki >>> table.floatright {
  margin: 0 0 0.5em 0.5em;
}
.wiki >>> div.floatleft,
.wiki >>> table.floatleft {
  margin: 0 0.5em 0.5em 0;
}
.wiki >>> div.thumb {
  width: auto;
  background-color: transparent;
  margin-bottom: 0.5em;
}
.wiki >>> div.tleft {
  margin: 0.5em 1.4em 1.3em 0;
}
.wiki >>> div.tright {
  margin: 0.5em 0 1.3em 1.4em;
}
.wiki >>> .thumbcaption {
  text-align: left;
  line-height: 1.4em;
  padding: 3px;
}
.wiki >>> div.thumbinner {
  padding: 3px;
  text-align: center;
  overflow: hidden;
}
.wiki >>> .messagebox,
.wiki >>> .errorbox,
.wiki >>> .warningbox,
.wiki >>> .successbox {
  color: #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 16px;
  border: 1px solid;
  padding: 12px 24px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.wiki >>> .messagebox :only-child,
.wiki >>> .errorbox :only-child,
.wiki >>> .warningbox :only-child,
.wiki >>> .successbox :only-child {
  margin: 0;
}
.wiki >>> .messagebox h2,
.wiki >>> .errorbox h2,
.wiki >>> .warningbox h2,
.wiki >>> .successbox h2 {
  color: inherit;
  display: inline;
  margin: 0 0.5em 0 0;
  border: 0;
  font-size: 1em;
  font-weight: bold;
}
.wiki >>> .messagebox .mw-logevent-loglines li,
.wiki >>> .errorbox .mw-logevent-loglines li,
.wiki >>> .warningbox .mw-logevent-loglines li,
.wiki >>> .successbox .mw-logevent-loglines li {
  font-size: 90%;
}
.wiki >>> .messagebox {
  background-color: #eaecf0;
  border-color: #a2a9b1;
}
.wiki >>> .errorbox {
  background-color: #fee7e6;
  border-color: #d33;
}
.wiki >>> .warningbox {
  background-color: #fef6e7;
  border-color: #fc3;
}
.wiki >>> .successbox {
  background-color: #d5fdf4;
  border-color: #14866d;
}
.wiki >>> .toctogglecheckbox:checked ~ ul {
  display: none;
}
@media screen {
  .wiki >>> html,
  .wiki >>> body {
    height: 100%;
  }
  .wiki >>> :focus {
    outline-color: #3366cc;
  }
  .wiki >>> html {
    font-size: 100%;
  }
  .wiki >>> html,
  .wiki >>> body {
    font-family: sans-serif;
  }
  .wiki >>> ul {
    list-style-image: url(/w/skins/Vector/resources/common/images/bullet-icon.svg?d4515);
  }
  .wiki >>> pre,
  .wiki >>> .mw-code {
    line-height: 1.3;
  }
  .wiki >>> .mw-jump-link:not(:focus) {
    display: block;
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  .wiki >>> .mw-editsection,
  .wiki >>> .mw-editsection-like {
    font-family: sans-serif;
  }
  .wiki >>> .mw-body h1,
  .wiki >>> .mw-body-content h1,
  .wiki >>> .mw-body-content h2 {
    margin-bottom: 0.25em;
    padding: 0;
    font-family: "Linux Libertine", "Georgia", "Times", serif;
    line-height: 1.3;
  }
  .wiki >>> .mw-body h1:lang(ja),
  .wiki >>> .mw-body-content h1:lang(ja),
  .wiki >>> .mw-body-content h2:lang(ja),
  .wiki >>> .mw-body h1:lang(he),
  .wiki >>> .mw-body-content h1:lang(he),
  .wiki >>> .mw-body-content h2:lang(he),
  .wiki >>> .mw-body h1:lang(ko),
  .wiki >>> .mw-body-content h1:lang(ko),
  .wiki >>> .mw-body-content h2:lang(ko) {
    font-family: sans-serif;
  }
  .wiki >>> .mw-body h1:lang(my),
  .wiki >>> .mw-body-content h1:lang(my),
  .wiki >>> .mw-body-content h2:lang(my) {
    line-height: normal;
  }
  .wiki >>> .mw-body h1,
  .wiki >>> .mw-body-content h1 {
    font-size: 1.8em;
  }
  .wiki >>> .vector-body {
    font-size: 0.875em;
    font-size: calc(1em * 0.875);
    line-height: 1.6;
  }
  .wiki >>> .vector-body h1 {
    margin-top: 1em;
  }
  .wiki >>> .vector-body h2 {
    margin-top: 1em;
    font-size: 1.5em;
  }
  .wiki >>> .vector-body h3,
  .wiki >>> .vector-body h4,
  .wiki >>> .vector-body h5,
  .wiki >>> .vector-body h6 {
    margin-top: 0.3em;
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 1.6;
  }
  .wiki >>> .vector-body h3 {
    font-size: 1.2em;
  }
  .wiki >>> .vector-body h3,
  .wiki >>> .vector-body h4 {
    font-weight: bold;
  }
  .wiki >>> .vector-body h4,
  .wiki >>> .vector-body h5,
  .wiki >>> .vector-body h6 {
    font-size: 100%;
  }
  .wiki >>> .vector-body .toc h2 {
    font-family: sans-serif;
    font-size: 100%;
  }
  .wiki >>> .vector-body p {
    margin: 0.5em 0;
  }
  .wiki >>> .vector-body blockquote {
    border-left: 4px solid #eaecf0;
    padding: 8px 32px;
  }
  .wiki >>> .vector-body blockquote > :first-child {
    margin-top: 0;
  }
  .wiki >>> .vector-body blockquote > :last-child {
    margin-bottom: 0;
  }
  .wiki >>> .mw-parser-output a.external {
    background-image: url(/w/skins/Vector/resources/common/images/external-link-ltr-icon.svg?48e54);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-body .mw-indicators {
    font-size: 0.875em;
    line-height: 1.6;
    position: relative;
  }
  .wiki >>> .mw-body .mw-indicator {
    display: inline-block;
  }
  .wiki >>> #siteNotice {
    font-size: 0.8em;
  }
  .wiki >>> .mw-portlet h3 {
    display: none;
  }
  .wiki >>> .mw-portlet ul {
    list-style: none none;
    margin: 0;
  }
  .wiki >>> .vector-menu-tabs {
    background-position: left bottom;
    float: left;
    height: 2.5em;
    padding-left: 1px;
  }
  .wiki >>> .vector-menu-tabs h3 {
    display: none;
  }
  .wiki >>> .vector-menu-tabs ul {
    float: left;
    height: 100%;
    list-style: none none;
    margin: 0;
    padding: 0;
  }
  .wiki >>> .vector-menu-tabs li {
    background-image: url(/w/skins/Vector/resources/common/images/tab-normal-fade.png?1cc52);
    background-image: linear-gradient(
      to top,
      #77c1f6 0,
      #e8f2f8 1px,
      #ffffff 100%
    );
    background-position: left bottom;
    background-repeat: repeat-x;
    float: left;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.125em;
    white-space: nowrap;
  }
  .wiki >>> .vector-menu-tabs li a {
    background-position: right bottom;
    color: #0645ad;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    float: left;
    height: 3.07692308em;
    position: relative;
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 0.8125em;
    cursor: pointer;
  }
  .wiki >>> .vector-menu-tabs .new a,
  .wiki >>> .vector-menu-tabs .new a:visited {
    color: #dd3333;
  }
  .wiki >>> .vector-menu-tabs .selected {
    background: #ffffff;
  }
  .wiki >>> .vector-menu-tabs .selected a,
  .wiki >>> .vector-menu-tabs .selected a:visited {
    color: #202122;
    text-decoration: none;
  }
  .wiki >>> .vector-menu-tabs .icon a {
    background-position: right bottom;
    background-repeat: no-repeat;
  }
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .wiki >>> .vector-menu-tabs .mw-watchlink.icon a {
    width: 2.15384615em;
    height: 0;
    padding: 3.07692308em 0 0 0;
    overflow: hidden;
  }
  .wiki >>> .vector-menu-tabs .mw-watchlink.icon a:before {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    content: "";
    display: block;
    position: absolute;
    top: 1.07692308em;
    left: 0.38461538em;
    width: 1.23076923em;
    height: 1.23076923em;
  }
  .wiki >>> .vector-menu-tabs #ca-unwatch.icon a:before {
    background-image: linear-gradient(transparent, transparent),
      url(/w/skins/Vector/resources/common/images/unwatch-icon.svg?c5368);
  }
  .wiki >>> .vector-menu-tabs #ca-unwatch.mw-watchlink-temp.icon a:before {
    background-image: linear-gradient(transparent, transparent),
      url(/w/skins/Vector/resources/common/images/unwatch-temp-icon.svg?195f5);
  }
  .wiki >>> .vector-menu-tabs #ca-watch.icon a:before {
    background-image: linear-gradient(transparent, transparent),
      url(/w/skins/Vector/resources/common/images/watch-icon.svg?6ab6f);
  }
  .wiki >>> .vector-menu-tabs #ca-unwatch.icon a:hover:before,
  .wiki >>> .vector-menu-tabs #ca-unwatch.icon a:focus:before {
    background-image: linear-gradient(transparent, transparent),
      url(/w/skins/Vector/resources/common/images/unwatch-icon-hl.svg?5e652);
  }
  .wiki >>> .vector-menu-tabs #ca-unwatch.mw-watchlink-temp.icon a:hover:before,
  .wiki
    >>> .vector-menu-tabs
    #ca-unwatch.mw-watchlink-temp.icon
    a:focus:before {
    background-image: linear-gradient(transparent, transparent),
      url(/w/skins/Vector/resources/common/images/unwatch-temp-icon-hl.svg?f3db6);
  }
  .wiki >>> .vector-menu-tabs #ca-watch.icon a:hover:before,
  .wiki >>> .vector-menu-tabs #ca-watch.icon a:focus:before {
    background-image: linear-gradient(transparent, transparent),
      url(/w/skins/Vector/resources/common/images/watch-icon-hl.svg?9bf90);
  }
  .wiki >>> .vector-menu-tabs #ca-unwatch.icon .loading:before,
  .wiki >>> .vector-menu-tabs #ca-watch.icon .loading:before {
    -webkit-animation: rotate 700ms infinite linear;
    animation: rotate 700ms infinite linear;
    outline: 0;
    cursor: default;
    pointer-events: none;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .wiki >>> .vector-menu-dropdown {
    direction: ltr;
    float: left;
    cursor: pointer;
    position: relative;
    line-height: 1.125em;
  }
  .wiki >>> .vector-menu-dropdown h3 {
    color: #54595d;
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .wiki >>> .vector-menu-dropdown h3:after {
    content: "";
    background-image: url(/w/skins/Vector/resources/common/images/arrow-down.svg?9426f);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0.76923077em;
    right: 8px;
    bottom: 0;
    width: 1.23076923em;
    opacity: 0.84;
  }
  .wiki >>> .vector-menu-dropdown h3:hover,
  .wiki >>> .vector-menu-dropdown h3:focus {
    color: #202122;
  }
  .wiki >>> .vector-menu-dropdown h3:hover:after,
  .wiki >>> .vector-menu-dropdown h3:focus:after {
    opacity: 1;
  }
  .wiki >>> .vector-menu-dropdown .vector-menu-content {
    background-color: #ffffff;
    list-style: none none;
    min-width: 100%;
    position: absolute;
    top: 2.5em;
    left: -1px;
    margin: 0;
    border: 1px solid #a2a9b1;
    border-top-width: 0;
    padding: 0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 100ms;
    transition: opacity 100ms;
    z-index: 3;
  }
  .wiki >>> .vector-menu-dropdown .vector-menu-checkbox-expanded,
  .wiki >>> .vector-menu-dropdown .vector-menu-checkbox-collapsed {
    display: block;
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  .wiki
    >>> .vector-menu-dropdown
    .vector-menu-checkbox:checked
    + .vector-menu-heading
    .vector-menu-checkbox-expanded,
  .wiki
    >>> .vector-menu-dropdown
    .vector-menu-checkbox:not(:checked)
    + .vector-menu-heading
    .vector-menu-checkbox-collapsed {
    display: inline !important;
  }
  .wiki
    >>> .vector-menu-dropdown
    .vector-menu-checkbox:not(:checked)
    + .vector-menu-heading
    .vector-menu-checkbox-expanded,
  .wiki
    >>> .vector-menu-dropdown
    .vector-menu-checkbox:checked
    + .vector-menu-heading
    .vector-menu-checkbox-collapsed {
    display: none !important;
  }
  .wiki
    >>> .vector-menu-dropdown
    .vector-menu-checkbox:checked
    ~ .vector-menu-content {
    opacity: 1;
    visibility: visible;
  }
  .wiki >>> .vector-menu-dropdown ul {
    list-style: none;
  }
  .wiki >>> .vector-menu-dropdown li {
    padding: 0;
    margin: 0;
    text-align: left;
    line-height: 1em;
  }
  .wiki >>> .vector-menu-dropdown li a {
    padding: 0.625em;
    color: #0645ad;
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }
  .wiki >>> .vector-menu-dropdown li a span {
    font-size: 0.8125em;
  }
  .wiki >>> .vector-menu-dropdown .selected a,
  .wiki >>> .vector-menu-dropdown .selected a:visited {
    color: #202122;
    text-decoration: none;
  }
  .wiki >>> #mw-head .vector-menu-dropdown h3 {
    background-position: right bottom;
    float: left;
    height: 3.07692308em;
    margin: 0 -1px 0 0;
    padding-right: 1.84615385em;
  }
  .wiki >>> .vector-menu-tabs,
  .wiki >>> .vector-menu-tabs a,
  .wiki >>> #mw-head .vector-menu-dropdown h3 {
    background-image: url(/w/skins/Vector/resources/common/images/tab-separator.png?09d4b);
    background-image: linear-gradient(
      to bottom,
      rgba(167, 215, 249, 0) 0,
      #a7d7f9 100%
    );
    background-repeat: no-repeat;
    background-size: 1px 100%;
  }
  .wiki >>> .vector-menu-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: none;
  }
  .wiki >>> :not(:checked) > .vector-menu-checkbox {
    display: block;
  }
  .wiki >>> .vector-menu-dropdown-noicon h3 {
    padding-top: 1.25em;
    padding-left: 8px;
    padding-right: 1.84615385em;
    font-size: 0.8125em;
    font-weight: normal;
  }
  .wiki >>> .vector-menu-dropdown-noicon .vector-menu-checkbox:focus + h3 {
    outline: dotted 1px;
    outline: auto -webkit-focus-ring-color;
  }
  .wiki >>> .vector-menu-portal {
    margin: 0 0.6em 0 0.7em;
    padding: 0.25em 0;
    direction: ltr;
  }
  .wiki >>> .vector-menu-portal h3 {
    display: block;
    background-image: url(/w/skins/Vector/resources/common/images/portal-separator.png?4ab04);
    background-image: linear-gradient(
      to right,
      rgba(200, 204, 209, 0) 0,
      #c8ccd1 33%,
      #c8ccd1 66%,
      rgba(200, 204, 209, 0) 100%
    );
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: #54595d;
    margin: 0.5em 0 0 0.66666667em;
    border: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    font-weight: normal;
    cursor: default;
  }
  .wiki >>> .vector-menu-portal .vector-menu-content {
    margin-left: 0.5em;
    padding-top: 0;
  }
  .wiki >>> .vector-menu-portal .vector-menu-content ul {
    list-style: none none;
    margin: 0;
    padding-top: 0.3em;
  }
  .wiki >>> .vector-menu-portal .vector-menu-content li {
    margin: 0;
    padding: 0.25em 0;
    font-size: 0.75em;
    line-height: 1.125em;
    word-wrap: break-word;
  }
  .wiki >>> .vector-menu-portal .vector-menu-content li a {
    color: #0645ad;
  }
  .wiki >>> .vector-menu-portal .vector-menu-content li a:visited {
    color: #0b0080;
  }
  .wiki >>> .vector-search-box-inner {
    position: relative;
    height: 100%;
  }
  .wiki >>> .vector-search-box-input {
    background-color: rgba(255, 255, 255, 0.5);
    color: #000000;
    width: 100%;
    height: 2.15384615em;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #a2a9b1;
    border-radius: 2px;
    padding: 5px 2.15384615em 5px 0.4em;
    box-shadow: inset 0 0 0 1px transparent;
    font-family: inherit;
    font-size: 0.8125em;
    direction: ltr;
    -webkit-transition: border-color 250ms, box-shadow 250ms;
    transition: border-color 250ms, box-shadow 250ms;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
  .wiki >>> .vector-search-box-inner:hover .vector-search-box-input {
    border-color: #72777d;
  }
  .wiki >>> .vector-search-box-input:focus,
  .wiki >>> .vector-search-box-inner:hover .vector-search-box-input:focus {
    outline: 0;
    border-color: #3366cc;
    box-shadow: inset 0 0 0 1px #3366cc;
  }
  .wiki >>> .vector-search-box-input::-webkit-input-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki >>> .vector-search-box-input:-ms-input-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki >>> .vector-search-box-input::-moz-placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki >>> .vector-search-box-input::placeholder {
    color: #72777d;
    opacity: 1;
  }
  .wiki >>> .vector-search-box-input::-webkit-search-decoration,
  .wiki >>> .vector-search-box-input::-webkit-search-cancel-button,
  .wiki >>> .vector-search-box-input::-webkit-search-results-button,
  .wiki >>> .vector-search-box-input::-webkit-search-results-decoration {
    display: none;
  }
  .wiki >>> .searchButton {
    background-color: transparent;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    min-width: 28px;
    width: 2.15384615em;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 0.8125em;
    direction: ltr;
    text-indent: -99999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
  }
  .wiki >>> .searchButton[name="go"] {
    background: no-repeat center / 1.23076923em
      url(/w/skins/Vector/resources/common/images/search.svg?bbf78);
    opacity: 0.67;
  }
  .wiki >>> .search-toggle {
    display: block;
    float: right;
  }
  .wiki >>> .vector-search-box-collapses > div {
    display: none;
  }
  .wiki >>> #p-logo {
    width: 10em;
    height: 160px;
    margin-bottom: 1em;
  }
  .wiki >>> #p-logo a {
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 10em;
    height: 160px;
    text-decoration: none;
  }
  .wiki >>> .mw-footer {
    direction: ltr;
  }
  .wiki >>> .mw-footer ul {
    list-style: none none;
    margin: 0;
    padding: 0;
  }
  .wiki >>> .mw-footer li {
    color: #202122;
    margin: 0;
    padding: 0.5em 0;
    font-size: 0.75em;
  }
  .wiki >>> #footer-icons {
    float: right;
  }
  .wiki >>> #footer-icons li {
    float: left;
    margin-left: 0.5em;
    line-height: 2;
    text-align: right;
  }
  .wiki >>> #footer-info li {
    line-height: 1.4;
  }
  .wiki >>> #footer-places li {
    float: left;
    margin-right: 1em;
    line-height: 2;
  }
}
@media screen and (min-width: 1000px) {
  .wiki >>> .search-toggle {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .wiki >>> .vector-search-box-collapses > div {
    display: block;
  }
}
@media print {
  .wiki >>> .toc,
  .wiki >>> body {
    padding: 10px;
    font-family: "Linux Libertine", "Georgia", "Times", serif;
  }
  .wiki >>> .printfooter,
  .wiki >>> .mw-footer,
  .wiki >>> .thumb,
  .wiki >>> figure,
  .wiki >>> table,
  .wiki >>> ol,
  .wiki >>> dl,
  .wiki >>> ul,
  .wiki >>> h3,
  .wiki >>> h4,
  .wiki >>> h5,
  .wiki >>> h6 {
    font-family: sans-serif;
  }
  .wiki >>> img {
    font-family: "Linux Libertine", "Georgia", "Times", serif;
  }
  .wiki >>> .mw-body a:not(.image) {
    border-bottom: 1px solid #aaa;
  }
  .wiki >>> .firstHeading {
    font-size: 25pt;
    line-height: 28pt;
    margin-bottom: 20px;
    padding-bottom: 5px;
  }
  .wiki >>> .firstHeading,
  .wiki >>> h2 {
    overflow: hidden;
    border-bottom: 2px solid #000000;
  }
  .wiki >>> h3,
  .wiki >>> h4,
  .wiki >>> h5,
  .wiki >>> h6 {
    margin: 30px 0 0;
  }
  .wiki >>> h2,
  .wiki >>> h3,
  .wiki >>> h4,
  .wiki >>> h5,
  .wiki >>> h6 {
    padding: 0;
    position: relative;
  }
  .wiki >>> h2 {
    font-size: 18pt;
    line-height: 24pt;
    margin-bottom: 0.25em;
  }
  .wiki >>> h3 {
    font-size: 14pt;
    line-height: 20pt;
  }
  .wiki >>> h4,
  .wiki >>> h5,
  .wiki >>> h6 {
    font-size: 12pt;
    line-height: 16pt;
  }
  .wiki >>> p {
    font-size: 12pt;
    line-height: 16pt;
    margin-top: 5px;
    text-align: justify;
  }
  .wiki >>> p:before {
    content: "";
    display: block;
    width: 120pt;
    overflow: hidden;
  }
  .wiki >>> blockquote {
    border-left: 2px solid #000000;
    padding-left: 20px;
  }
  .wiki >>> ol,
  .wiki >>> ul {
    margin: 10px 0 0 1.6em;
    padding: 0;
  }
  .wiki >>> ol li,
  .wiki >>> ul li {
    padding: 2px 0;
    font-size: 12pt;
  }
  .wiki >>> table ol li,
  .wiki >>> table ul li {
    font-size: inherit;
  }
  .wiki >>> .toc {
    page-break-before: avoid;
    page-break-after: avoid;
    background: none;
    border: 0;
    display: table;
  }
  .wiki >>> .toc a {
    border: 0;
    font-weight: normal;
  }
  .wiki >>> .toc > ul > li {
    margin-bottom: 4px;
    font-weight: bold;
  }
  .wiki >>> .toc ul {
    margin: 0;
    list-style: none;
  }
  .wiki >>> .toc ul ul {
    padding-left: 30px;
  }
  .wiki >>> .toc li.toclevel-1 > a {
    font-size: 12pt;
    font-weight: bold;
  }
  .wiki >>> #mw-navigation,
  .wiki >>> .noprint,
  .wiki >>> .mw-jump-link,
  .wiki >>> .mw-portlet-lang,
  .wiki >>> .toc .tocnumber {
    display: none;
  }
  .wiki >>> .printfooter {
    margin-top: 10px;
    border-top: 3px solid #000000;
    padding-top: 10px;
    font-size: 10pt;
    clear: both;
  }
  .wiki >>> .mw-footer {
    margin-top: 12px;
    border-top: 1px solid #eeeeee;
    padding-top: 5px;
  }
  .wiki >>> #footer-info {
    margin: 0;
    padding: 0;
  }
  .wiki >>> #footer-info li {
    color: #999;
    list-style: none;
    display: block;
    padding-bottom: 10px;
    font-size: 10pt;
  }
  .wiki >>> #footer-info li a {
    color: #999 !important;
  }
  .wiki >>> #footer-info-lastmod {
    color: #000000;
    font-size: 12pt;
    font-weight: bold;
  }
}
@media screen {
  .wiki >>> body {
    background-color: #f6f6f6;
    overflow-y: scroll;
  }
  .wiki >>> .mw-body,
  .wiki >>> .parsoid-body {
    background-color: #ffffff;
    color: #202122;
    direction: ltr;
    padding: 1em;
  }
  .wiki >>> #p-personal {
    position: absolute;
    top: 6px;
    right: 0.75em;
    z-index: 100;
  }
  .wiki >>> #p-personal ul {
    padding-left: 10em;
  }
  .wiki >>> #p-personal li {
    float: left;
    margin-left: 0.75em;
    padding-top: 0.5em;
    line-height: 1.16666667;
  }
  .wiki >>> .mw-body,
  .wiki >>> #mw-data-after-content {
    margin-left: 10em;
  }
  .wiki >>> .mw-body {
    margin-top: -1px;
    border: 1px solid #a7d7f9;
    border-right-width: 0;
  }
  .wiki >>> .mw-body .firstHeading {
    overflow: visible;
  }
  .wiki >>> .mw-indicators {
    z-index: 1;
  }
  .wiki >>> .vector-body {
    position: relative;
    z-index: 0;
  }
  .wiki >>> #mw-page-base {
    background-color: #fbfbfb;
    background-image: linear-gradient(to bottom, #ffffff 50%, #f6f6f6 100%);
    background-position: bottom left;
    height: 5em;
  }
  .wiki >>> #mw-head-base {
    margin-top: -5em;
    margin-left: 10em;
    height: 5em;
  }
  .wiki >>> #mw-navigation h2 {
    position: absolute;
    top: -9999px;
  }
  .wiki >>> #mw-head {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
  .wiki >>> #left-navigation {
    float: left;
    margin-left: 10em;
    margin-top: 2.5em;
    margin-bottom: -2.5em;
  }
  .wiki >>> #right-navigation {
    float: right;
    margin-top: 2.5em;
  }
  .wiki >>> .vector-search-box {
    float: left;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
  .wiki >>> .vector-search-box form {
    margin: 0.5em 0 0;
  }
  .wiki >>> .vector-search-box-inner {
    min-width: 5em;
    width: 13.2em;
    width: 20vw;
    max-width: 20em;
  }
  .wiki >>> #mw-panel {
    position: absolute;
    top: 0;
    width: 10em;
    left: 0;
  }
  .wiki >>> #p-logo + .mw-portlet h3 {
    display: none;
  }
  .wiki >>> .mw-footer {
    margin-left: 10em;
    margin-top: 0;
    padding: 0.75em;
  }
  .wiki >>> .mw-content-ltr {
    direction: ltr;
  }
  .wiki >>> .mw-content-rtl {
    direction: rtl;
  }
  .wiki
    >>> .vector-menu-dropdown:not(.vector-user-menu):hover
    .vector-menu-content {
    opacity: 1;
    visibility: visible;
  }
  .wiki >>> .vector-menu-checkbox:checked + h3:after {
    transform: scaleY(-1);
  }
  .wiki >>> .vector-menu-dropdown li a {
    padding: 0.5078125em;
  }
  .wiki >>> .vector-search-box h3 {
    display: block;
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
  .wiki >>> #mw-panel {
    font-size: inherit;
  }
  .wiki >>> #mw-panel nav:first-child {
    background-image: none;
  }
  .wiki >>> #mw-panel nav:first-child h3 {
    display: none;
  }
  .wiki >>> #mw-panel nav:first-child .vector-menu-content {
    margin-left: 0.5em;
  }
  .wiki >>> .vector-user-menu-legacy {
  }
  .wiki >>> .vector-user-menu-legacy li {
    font-size: 0.75em;
  }
  .wiki >>> .vector-user-menu-legacy #pt-anonuserpage,
  .wiki >>> .vector-user-menu-legacy #pt-userpage a {
    background-position: left 0.33333333em;
    background-image: url(/w/skins/Vector/resources/skins.vector.styles.legacy/images/user-avatar.svg?b7f58);
    background-repeat: no-repeat;
    background-size: 1.16666667em 1.16666667em;
    padding-top: 0.5em;
    padding-left: 1.5em;
  }
  .wiki >>> .vector-user-menu-legacy #pt-userpage {
    padding-top: 0;
  }
  .wiki >>> .vector-user-menu-legacy #pt-userpage a {
    display: inline-block;
  }
  .wiki >>> .vector-user-menu-legacy #pt-anonuserpage {
    color: #54595d;
  }
  .wiki >>> .vector-user-menu-legacy .vector-menu-content-list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: baseline;
  }
  .wiki >>> .skin-vector-legacy a.new {
    color: #ba0000;
  }
  .wiki >>> .skin-vector-legacy a.new:visited,
  .wiki >>> .skin-vector-legacy .vector-menu-tabs .new a,
  .wiki >>> .skin-vector-legacy .vector-menu-tabs .new a:visited {
    color: #a55858;
  }
}
@media screen and (min-width: 982px) {
  .wiki >>> .mw-body,
  .wiki >>> #mw-head-base,
  .wiki >>> #left-navigation,
  .wiki >>> #mw-data-after-content,
  .wiki >>> .mw-footer {
    margin-left: 11em;
  }
  .wiki >>> .mw-body {
    padding: 1.25em 1.5em 1.5em 1.5em;
  }
  .wiki >>> .mw-footer {
    padding: 1.25em;
  }
  .wiki >>> #mw-panel {
    padding-left: 0.5em;
  }
  .wiki >>> .vector-search-box {
    margin-right: 1em;
  }
  .wiki >>> #p-personal {
    right: 1em;
  }
}
@media print {
  .wiki >>> .noprint,
  .wiki >>> .mw-cite-backlink,
  .wiki >>> .mw-redirectedfrom,
  .wiki >>> .patrollink,
  .wiki >>> #column-one,
  .wiki >>> #footer-places,
  .wiki >>> #mw-navigation,
  .wiki >>> #f-poweredbyico,
  .wiki >>> #f-copyrightico,
  .wiki >>> li#about,
  .wiki >>> li#disclaimer,
  .wiki >>> li#mobileview,
  .wiki >>> li#privacy {
    display: none;
  }
  .wiki >>> #footer {
    background: #fff;
    color: #000;
    margin-top: 1em;
    border-top: 1pt solid #aaa;
    padding-top: 5px;
    direction: ltr;
  }
}
.wiki >>> .mw-wiki-logo {
  background-image: url(/static/images/project-logos/eswiki.png);
}
@media print {
  .wiki >>> .firstHeading:before {
    left: auto;
    position: relative;
  }
  .wiki >>> table.floatright,
  .wiki >>> div.floatright {
    border: 0;
  }
  .wiki >>> table.floatleft,
  .wiki >>> div.floatleft {
    border: 0;
  }
  .wiki >>> div.thumb {
    page-break-inside: avoid;
  }
  .wiki >>> div.thumb a {
    border-bottom: 0;
  }
  .wiki >>> div.thumbinner {
    border: 1px;
    background-color: #ffffff;
    font-size: 10pt;
    color: #666666;
    border-radius: 2px;
    min-width: 100px;
  }
  .wiki >>> .thumbborder {
    border: 1pt solid #dddddd;
  }
  .wiki >>> .magnify {
    display: none;
  }
  .wiki >>> .mw-parser-output a.external {
  }
  .wiki >>> .mw-parser-output a.external.text:after,
  .wiki >>> .mw-parser-output a.external.autonumber:after {
    content: " (" attr(href) ")";
    word-break: break-all;
    word-wrap: break-word;
  }
  .wiki >>> .mw-parser-output a.external.text[href^='//']:after,.mw-parser-output a.external.autonumber[href^='//']:after
  {
    content: " (https:" attr(href) ")";
  }
  .wiki >>> .wikitable {
    background: #fff;
    margin: 1em 0;
    border: 1pt solid #aaa;
    border-collapse: collapse;
    font-size: 10pt;
    page-break-inside: avoid;
  }
  .wiki >>> .wikitable > caption {
    padding: 5px;
    font-size: 10pt;
  }
  .wiki >>> .wikitable > tr > th,
  .wiki >>> .wikitable > tr > td,
  .wiki >>> .wikitable > * > tr > th,
  .wiki >>> .wikitable > * > tr > td {
    background: #fff !important;
    color: #000 !important;
    border: 1pt solid #aaa;
    padding: 0.4em 0.6em;
  }
  .wiki >>> .wikitable > tr > th,
  .wiki >>> .wikitable > * > tr > th {
    text-align: center;
  }
  .wiki >>> table.listing,
  .wiki >>> table.listing td {
    border: 1pt solid #000;
    border-collapse: collapse;
  }
  .wiki >>> .mw-editsection,
  .wiki >>> .mw-editsection-like,
  .wiki >>> .mw-indicators,
  .wiki >>> #siteNotice,
  .wiki >>> .usermessage {
    display: none;
  }
  .wiki >>> .printfooter {
    padding: 1em 0;
  }
  .wiki >>> .catlinks ul {
    display: inline;
    padding: 0;
    list-style: none;
  }
  .wiki >>> .catlinks li {
    display: inline-block;
    line-height: 1.15;
    margin: 0.1em 0;
    border-left: 1pt solid #aaa;
    padding: 0 0.4em;
  }
  .wiki >>> .catlinks li:first-child {
    border-left: 0;
    padding-left: 0.2em;
  }
  .wiki >>> .mw-hidden-catlinks,
  .wiki >>> .catlinks {
    display: none;
  }
  .wiki >>> .center {
    text-align: center;
  }
  .wiki >>> a {
    background: none !important;
    padding: 0 !important;
  }
  .wiki >>> a,
  .wiki >>> a.external,
  .wiki >>> a.new,
  .wiki >>> a.stub {
    color: inherit !important;
    text-decoration: inherit !important;
  }
  .wiki >>> dt {
    font-weight: bold;
  }
  .wiki >>> h1,
  .wiki >>> h2,
  .wiki >>> h3,
  .wiki >>> h4,
  .wiki >>> h5,
  .wiki >>> h6 {
    font-weight: bold;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .wiki >>> p {
    margin: 1em 0;
    line-height: 1.2;
    orphans: 3;
    widows: 3;
  }
  .wiki >>> img,
  .wiki >>> figure {
    page-break-inside: avoid;
  }
  .wiki >>> img {
    border: 0;
    vertical-align: middle;
  }
  .wiki >>> pre,
  .wiki >>> .mw-code {
    background: #fff;
    color: #000;
    border: 1pt dashed #000;
    padding: 1em;
    font-size: 8pt;
    white-space: pre-wrap;
    overflow-x: hidden;
    word-wrap: break-word;
  }
  .wiki >>> ul {
    list-style-type: square;
  }
  .wiki >>> .toctogglecheckbox:checked + .toctitle {
    display: none;
  }
  .wiki >>> .toc {
    background-color: #f9f9f9;
    border: 1pt solid #aaa;
    padding: 5px;
    display: table;
  }
  .wiki >>> .tocnumber,
  .wiki >>> .toctext {
    display: table-cell;
  }
  .wiki >>> .tocnumber {
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki >>> .mw-content-ltr .tocnumber {
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki >>> .mw-content-rtl .tocnumber {
    padding-left: 0.5em;
    padding-right: 0;
  }
}
@media screen {
  .wiki >>> div.thumbinner {
    border: 1px solid #c8ccd1;
    background-color: #f8f9fa;
    font-size: 94%;
  }
  .wiki >>> .thumbimage {
    background-color: #ffffff;
    border: 1px solid #c8ccd1;
  }
  .wiki >>> .thumbcaption {
    border: 0;
    font-size: 94%;
  }
  .wiki >>> .thumbborder {
    border: 1px solid #eaecf0;
  }
  .wiki >>> .magnify {
    float: right;
    margin-left: 3px;
  }
  .wiki >>> .magnify a {
    display: block;
    text-indent: 15px;
    white-space: nowrap;
    overflow: hidden;
    width: 15px;
    height: 11px;
    background-image: url(/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .wiki >>> .mw-content-ltr .thumbcaption {
    text-align: left;
  }
  .wiki >>> .mw-content-rtl .thumbcaption {
    text-align: right;
  }
  .wiki >>> .mw-content-ltr .magnify {
    margin-left: 3px;
    margin-right: 0;
    float: right;
  }
  .wiki >>> .mw-content-ltr .magnify a {
    background-image: url(/w/resources/src/mediawiki.skinning/images/magnify-clip-ltr.svg?8330e);
  }
  .wiki >>> .mw-content-rtl .magnify {
    margin-left: 0;
    margin-right: 3px;
    float: left;
  }
  .wiki >>> .mw-content-rtl .magnify a {
    background-image: url(/w/resources/src/mediawiki.skinning/images/magnify-clip-rtl.svg?38fd5);
  }
  .wiki >>> a.new {
    color: #dd3333;
  }
  .wiki >>> a.mw-selflink {
    color: inherit;
    font-weight: bold;
    text-decoration: inherit;
  }
  .wiki >>> a.mw-selflink:hover {
    cursor: inherit;
    text-decoration: inherit;
  }
  .wiki >>> a.mw-selflink:active,
  .wiki >>> a.mw-selflink:visited {
    color: inherit;
  }
  .wiki >>> a.new:visited {
    color: #a55858;
  }
  .wiki >>> .mw-parser-output a.extiw,
  .wiki >>> .mw-parser-output a.external {
    color: #3366bb;
  }
  .wiki >>> .mw-parser-output a.extiw:visited,
  .wiki >>> .mw-parser-output a.external:visited {
    color: #663366;
  }
  .wiki >>> .mw-parser-output a.extiw:active,
  .wiki >>> .mw-parser-output a.external:active {
    color: #bb6633;
  }
  .wiki >>> .mw-underline-always a {
    text-decoration: underline;
  }
  .wiki >>> .mw-underline-never a {
    text-decoration: none;
  }
  .wiki >>> .plainlinks a.external {
    background: none !important;
    padding: 0 !important;
  }
  .wiki >>> .mw-parser-output a.external,
  .wiki >>> .link-https {
    background-image: url(/w/resources/src/mediawiki.skinning/images/external-ltr.svg?59558);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.external[href^="mailto:"],
  .wiki >>> .link-mailto {
    background-image: url(/w/resources/src/mediawiki.skinning/images/mail.svg?8a79c);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.external[href^='ftp://'],.link-ftp
  {
    background-image: url(/w/resources/src/mediawiki.skinning/images/ftp-ltr.svg?c5643);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.external[href^='irc://'],.mw-parser-output a.external[href^='ircs://'],.link-irc
  {
    background-image: url(/w/resources/src/mediawiki.skinning/images/chat-ltr.svg?e0649);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.external[href$=".ogg"],
  .wiki >>> .mw-parser-output a.external[href$=".OGG"],
  .wiki >>> .mw-parser-output a.external[href$=".mid"],
  .wiki >>> .mw-parser-output a.external[href$=".MID"],
  .wiki >>> .mw-parser-output a.external[href$=".midi"],
  .wiki >>> .mw-parser-output a.external[href$=".MIDI"],
  .wiki >>> .mw-parser-output a.external[href$=".mp3"],
  .wiki >>> .mw-parser-output a.external[href$=".MP3"],
  .wiki >>> .mw-parser-output a.external[href$=".wav"],
  .wiki >>> .mw-parser-output a.external[href$=".WAV"],
  .wiki >>> .mw-parser-output a.external[href$=".wma"],
  .wiki >>> .mw-parser-output a.external[href$=".WMA"],
  .wiki >>> .link-audio {
    background-image: url(/w/resources/src/mediawiki.skinning/images/audio-ltr.svg?74d04);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.external[href$=".ogm"],
  .wiki >>> .mw-parser-output a.external[href$=".OGM"],
  .wiki >>> .mw-parser-output a.external[href$=".avi"],
  .wiki >>> .mw-parser-output a.external[href$=".AVI"],
  .wiki >>> .mw-parser-output a.external[href$=".mpeg"],
  .wiki >>> .mw-parser-output a.external[href$=".MPEG"],
  .wiki >>> .mw-parser-output a.external[href$=".mpg"],
  .wiki >>> .mw-parser-output a.external[href$=".MPG"],
  .wiki >>> .link-video {
    background-image: url(/w/resources/src/mediawiki.skinning/images/video.svg?a3758);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.external[href$=".pdf"],
  .wiki >>> .mw-parser-output a.external[href$=".PDF"],
  .wiki >>> .mw-parser-output a.external[href*=".pdf#"],
  .wiki >>> .mw-parser-output a.external[href*=".PDF#"],
  .wiki >>> .mw-parser-output a.external[href*=".pdf?"],
  .wiki >>> .mw-parser-output a.external[href*=".PDF?"],
  .wiki >>> .link-document {
    background-image: url(/w/resources/src/mediawiki.skinning/images/document-ltr.svg?0bf90);
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 13px;
  }
  .wiki >>> .mw-parser-output a.extiw,
  .wiki >>> .mw-parser-output a.extiw:active {
    color: #36b;
  }
  .wiki >>> .mw-parser-output a.external {
    color: #36b;
  }
  .wiki >>> .mw-body-content:after {
    clear: both;
    content: "";
    display: block;
  }
  .wiki >>> .mw-body-content a.external.free {
    word-wrap: break-word;
  }
  .wiki >>> .mw-body-content .error {
    font-size: larger;
    color: #d33;
  }
  .wiki >>> .rtl .mw-parser-output a.external.free,
  .wiki >>> .rtl .mw-parser-output a.external.autonumber {
    direction: ltr;
    unicode-bidi: embed;
  }
  .wiki
    >>> .mw-hide-empty-elt
    .mw-parser-output:not(.mw-show-empty-elt)
    .mw-empty-elt {
    display: none;
  }
  .wiki >>> .wikitable {
    background-color: #f8f9fa;
    color: #202122;
    margin: 1em 0;
    border: 1px solid #a2a9b1;
    border-collapse: collapse;
  }
  .wiki >>> .wikitable > tr > th,
  .wiki >>> .wikitable > tr > td,
  .wiki >>> .wikitable > * > tr > th,
  .wiki >>> .wikitable > * > tr > td {
    border: 1px solid #a2a9b1;
    padding: 0.2em 0.4em;
  }
  .wiki >>> .wikitable > tr > th,
  .wiki >>> .wikitable > * > tr > th {
    background-color: #eaecf0;
    text-align: center;
  }
  .wiki >>> .wikitable > caption {
    font-weight: bold;
  }
  .wiki >>> .usermessage {
    background-color: #fef6e7;
    border: 1px solid #fc3;
    color: #000;
    font-weight: bold;
    margin: 2em 0 1em;
    padding: 0.5em 1em;
    vertical-align: middle;
  }
  .wiki >>> #siteNotice {
    position: relative;
    text-align: center;
    margin: 0;
  }
  .wiki >>> #localNotice {
    margin-bottom: 0.9em;
  }
  .wiki >>> #siteSub {
    display: none;
  }
  .wiki >>> #contentSub,
  .wiki >>> #contentSub2 {
    font-size: 84%;
    line-height: 1.2em;
    margin: 0 0 1.4em 1em;
    color: #54595d;
    width: auto;
  }
  .wiki >>> span.subpages {
    display: block;
  }
  .wiki >>> .emptyPortlet {
    display: none;
  }
  .wiki >>> .printfooter,
  .wiki >>> .client-nojs #t-print {
    display: none;
  }
  .wiki >>> .mw-indicators {
    float: right;
  }
  .wiki >>> .mw-editsection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .wiki >>> .mw-editsection,
  .wiki >>> .mw-editsection-like {
    font-size: small;
    font-weight: normal;
    margin-left: 1em;
    vertical-align: baseline;
    line-height: 1em;
  }
  .wiki >>> .mw-content-ltr .mw-editsection,
  .wiki >>> .mw-content-rtl .mw-content-ltr .mw-editsection,
  .wiki >>> .mw-content-ltr .mw-editsection-like,
  .wiki >>> .mw-content-rtl .mw-content-ltr .mw-editsection-like {
    margin-left: 1em;
    margin-right: 0;
  }
  .wiki >>> .mw-content-rtl .mw-editsection,
  .wiki >>> .mw-content-ltr .mw-content-rtl .mw-editsection,
  .wiki >>> .mw-content-rtl .mw-editsection-like,
  .wiki >>> .mw-content-ltr .mw-content-rtl .mw-editsection-like {
    margin-right: 1em;
    margin-left: 0;
  }
  .wiki >>> #catlinks {
    text-align: left;
  }
  .wiki >>> .catlinks {
    border: 1px solid #a2a9b1;
    background-color: #f8f9fa;
    padding: 5px;
    margin-top: 1em;
    clear: both;
  }
  .wiki >>> .catlinks ul {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .wiki >>> .catlinks li {
    display: inline-block;
    line-height: 1.25em;
    border-left: 1px solid #a2a9b1;
    margin: 0.125em 0;
    padding: 0 0.5em;
  }
  .wiki >>> .catlinks li:first-child {
    padding-left: 0.25em;
    border-left: 0;
  }
  .wiki >>> .catlinks li a.mw-redirect {
    font-style: italic;
  }
  .wiki >>> .mw-hidden-cats-hidden,
  .wiki >>> .catlinks-allhidden {
    display: none;
  }
  .wiki >>> a {
    text-decoration: none;
    color: #0645ad;
    background: none;
  }
  .wiki >>> a:not([href]) {
    cursor: pointer;
  }
  .wiki >>> a:visited {
    color: #0b0080;
  }
  .wiki >>> a:active {
    color: #faa700;
  }
  .wiki >>> a:hover,
  .wiki >>> a:focus {
    text-decoration: underline;
  }
  .wiki >>> a:lang(ar),
  .wiki >>> a:lang(kk-arab),
  .wiki >>> a:lang(mzn),
  .wiki >>> a:lang(ps),
  .wiki >>> a:lang(ur) {
    text-decoration: none;
  }
  .wiki >>> img {
    border: 0;
    vertical-align: middle;
  }
  .wiki >>> hr {
    height: 1px;
    background-color: #a2a9b1;
    border: 0;
    margin: 0.2em 0;
  }
  .wiki >>> h1,
  .wiki >>> h2,
  .wiki >>> h3,
  .wiki >>> h4,
  .wiki >>> h5,
  .wiki >>> h6 {
    color: #000;
    margin: 0;
    padding-top: 0.5em;
    padding-bottom: 0.17em;
    overflow: hidden;
  }
  .wiki >>> h1,
  .wiki >>> h2 {
    margin-bottom: 0.6em;
    border-bottom: 1px solid #a2a9b1;
  }
  .wiki >>> h3,
  .wiki >>> h4,
  .wiki >>> h5 {
    margin-bottom: 0.3em;
  }
  .wiki >>> h1 {
    font-size: 188%;
    font-weight: normal;
  }
  .wiki >>> h2 {
    font-size: 150%;
    font-weight: normal;
  }
  .wiki >>> h3 {
    font-size: 128%;
  }
  .wiki >>> h4 {
    font-size: 116%;
  }
  .wiki >>> h5 {
    font-size: 108%;
  }
  .wiki >>> h6 {
    font-size: 100%;
  }
  .wiki >>> p {
    margin: 0.4em 0 0.5em 0;
  }
  .wiki >>> p img {
    margin: 0;
  }
  .wiki >>> ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
  }
  .wiki >>> ol {
    margin: 0.3em 0 0 3.2em;
    padding: 0;
    list-style-image: none;
  }
  .wiki >>> li {
    margin-bottom: 0.1em;
  }
  .wiki >>> dt {
    font-weight: bold;
    margin-bottom: 0.1em;
  }
  .wiki >>> dl {
    margin-top: 0.2em;
    margin-bottom: 0.5em;
  }
  .wiki >>> dd {
    margin-left: 1.6em;
    margin-bottom: 0.1em;
  }
  .wiki >>> pre,
  .wiki >>> code,
  .wiki >>> tt,
  .wiki >>> kbd,
  .wiki >>> samp,
  .wiki >>> .mw-code {
    font-family: monospace, monospace;
  }
  .wiki >>> pre,
  .wiki >>> code,
  .wiki >>> .mw-code {
    background-color: #f8f9fa;
    color: #000;
    border: 1px solid #eaecf0;
  }
  .wiki >>> code {
    border-radius: 2px;
    padding: 1px 4px;
  }
  .wiki >>> pre,
  .wiki >>> .mw-code {
    padding: 1em;
    white-space: pre-wrap;
    overflow-x: hidden;
    word-wrap: break-word;
  }
  .wiki >>> table {
    font-size: 100%;
  }
  .wiki >>> fieldset {
    border: 1px solid #2a4b8d;
    margin: 1em 0 1em 0;
    padding: 0 1em 1em;
  }
  .wiki >>> legend {
    padding: 0.5em;
    font-size: 95%;
  }
  .wiki >>> form {
    border: 0;
    margin: 0;
  }
  .wiki >>> textarea {
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #c8ccd1;
    padding: 0.1em;
  }
  .wiki >>> .center {
    width: 100%;
    text-align: center;
  }
  .wiki >>> *.center * {
    margin-left: auto;
    margin-right: auto;
  }
  .wiki >>> .small {
    font-size: 94%;
  }
  .wiki >>> table.small {
    font-size: 100%;
  }
  @counter-style meetei {
    system: numeric;
    symbols: "\ABF0""\ABF1""\ABF2""\ABF3""\ABF4""\ABF5""\ABF6""\ABF7""\ABF8""\ABF9";
    suffix: ") ";
  }
  @counter-style santali {
    system: numeric;
    symbols: "\1C50""\1C51""\1C52""\1C53""\1C54""\1C55""\1C56""\1C57""\1C58""\1C59";
  }
  .wiki >>> ol:lang(azb) li,
  .wiki >>> ol:lang(bcc) li,
  .wiki >>> ol:lang(bgn) li,
  .wiki >>> ol:lang(bqi) li,
  .wiki >>> ol:lang(fa) li,
  .wiki >>> ol:lang(glk) li,
  .wiki >>> ol:lang(kk-arab) li,
  .wiki >>> ol:lang(lrc) li,
  .wiki >>> ol:lang(luz) li,
  .wiki >>> ol:lang(mzn) li {
    list-style-type: persian;
  }
  .wiki >>> ol:lang(ckb) li,
  .wiki >>> ol:lang(sdh) li {
    list-style-type: arabic-indic;
  }
  .wiki >>> ol:lang(hi) li,
  .wiki >>> ol:lang(mai) li,
  .wiki >>> ol:lang(mr) li,
  .wiki >>> ol:lang(ne) li {
    list-style-type: devanagari;
  }
  .wiki >>> ol:lang(as) li,
  .wiki >>> ol:lang(bn) li {
    list-style-type: bengali;
  }
  .wiki >>> ol:lang(mni) li {
    list-style-type: meetei;
  }
  .wiki >>> ol:lang(or) li {
    list-style-type: oriya;
  }
  .wiki >>> ol:lang(sat) li {
    list-style-type: santali;
  }
  .wiki >>> .mw-content-ltr ul,
  .wiki >>> .mw-content-rtl .mw-content-ltr ul {
    margin: 0.3em 0 0 1.6em;
    padding: 0;
  }
  .wiki >>> .mw-content-rtl ul,
  .wiki >>> .mw-content-ltr .mw-content-rtl ul {
    margin: 0.3em 1.6em 0 0;
    padding: 0;
  }
  .wiki >>> .mw-content-ltr ol,
  .wiki >>> .mw-content-rtl .mw-content-ltr ol {
    margin: 0.3em 0 0 3.2em;
    padding: 0;
  }
  .wiki >>> .mw-content-rtl ol,
  .wiki >>> .mw-content-ltr .mw-content-rtl ol {
    margin: 0.3em 3.2em 0 0;
    padding: 0;
  }
  .wiki >>> .mw-content-ltr dd,
  .wiki >>> .mw-content-rtl .mw-content-ltr dd {
    margin-left: 1.6em;
    margin-right: 0;
  }
  .wiki >>> .mw-content-rtl dd,
  .wiki >>> .mw-content-ltr .mw-content-rtl dd {
    margin-right: 1.6em;
    margin-left: 0;
  }
  .wiki >>> h1:lang(anp),
  .wiki >>> h1:lang(as),
  .wiki >>> h1:lang(bh),
  .wiki >>> h1:lang(bho),
  .wiki >>> h1:lang(bn),
  .wiki >>> h1:lang(gu),
  .wiki >>> h1:lang(hi),
  .wiki >>> h1:lang(kn),
  .wiki >>> h1:lang(ks),
  .wiki >>> h1:lang(ml),
  .wiki >>> h1:lang(mr),
  .wiki >>> h1:lang(my),
  .wiki >>> h1:lang(mai),
  .wiki >>> h1:lang(ne),
  .wiki >>> h1:lang(new),
  .wiki >>> h1:lang(or),
  .wiki >>> h1:lang(pa),
  .wiki >>> h1:lang(pi),
  .wiki >>> h1:lang(sa),
  .wiki >>> h1:lang(ta),
  .wiki >>> h1:lang(te) {
    line-height: 1.6em !important;
  }
  .wiki >>> h2:lang(anp),
  .wiki >>> h3:lang(anp),
  .wiki >>> h4:lang(anp),
  .wiki >>> h5:lang(anp),
  .wiki >>> h6:lang(anp),
  .wiki >>> h2:lang(as),
  .wiki >>> h3:lang(as),
  .wiki >>> h4:lang(as),
  .wiki >>> h5:lang(as),
  .wiki >>> h6:lang(as),
  .wiki >>> h2:lang(bho),
  .wiki >>> h3:lang(bho),
  .wiki >>> h4:lang(bho),
  .wiki >>> h5:lang(bho),
  .wiki >>> h6:lang(bho),
  .wiki >>> h2:lang(bh),
  .wiki >>> h3:lang(bh),
  .wiki >>> h4:lang(bh),
  .wiki >>> h5:lang(bh),
  .wiki >>> h6:lang(bh),
  .wiki >>> h2:lang(bn),
  .wiki >>> h3:lang(bn),
  .wiki >>> h4:lang(bn),
  .wiki >>> h5:lang(bn),
  .wiki >>> h6:lang(bn),
  .wiki >>> h2:lang(gu),
  .wiki >>> h3:lang(gu),
  .wiki >>> h4:lang(gu),
  .wiki >>> h5:lang(gu),
  .wiki >>> h6:lang(gu),
  .wiki >>> h2:lang(hi),
  .wiki >>> h3:lang(hi),
  .wiki >>> h4:lang(hi),
  .wiki >>> h5:lang(hi),
  .wiki >>> h6:lang(hi),
  .wiki >>> h2:lang(kn),
  .wiki >>> h3:lang(kn),
  .wiki >>> h4:lang(kn),
  .wiki >>> h5:lang(kn),
  .wiki >>> h6:lang(kn),
  .wiki >>> h2:lang(ks),
  .wiki >>> h3:lang(ks),
  .wiki >>> h4:lang(ks),
  .wiki >>> h5:lang(ks),
  .wiki >>> h6:lang(ks),
  .wiki >>> h2:lang(ml),
  .wiki >>> h3:lang(ml),
  .wiki >>> h4:lang(ml),
  .wiki >>> h5:lang(ml),
  .wiki >>> h6:lang(ml),
  .wiki >>> h2:lang(mr),
  .wiki >>> h3:lang(mr),
  .wiki >>> h4:lang(mr),
  .wiki >>> h5:lang(mr),
  .wiki >>> h6:lang(mr),
  .wiki >>> h2:lang(my),
  .wiki >>> h3:lang(my),
  .wiki >>> h4:lang(my),
  .wiki >>> h5:lang(my),
  .wiki >>> h6:lang(my),
  .wiki >>> h2:lang(mai),
  .wiki >>> h3:lang(mai),
  .wiki >>> h4:lang(mai),
  .wiki >>> h5:lang(mai),
  .wiki >>> h6:lang(mai),
  .wiki >>> h2:lang(ne),
  .wiki >>> h3:lang(ne),
  .wiki >>> h4:lang(ne),
  .wiki >>> h5:lang(ne),
  .wiki >>> h6:lang(ne),
  .wiki >>> h2:lang(new),
  .wiki >>> h3:lang(new),
  .wiki >>> h4:lang(new),
  .wiki >>> h5:lang(new),
  .wiki >>> h6:lang(new),
  .wiki >>> h2:lang(or),
  .wiki >>> h3:lang(or),
  .wiki >>> h4:lang(or),
  .wiki >>> h5:lang(or),
  .wiki >>> h6:lang(or),
  .wiki >>> h2:lang(pa),
  .wiki >>> h3:lang(pa),
  .wiki >>> h4:lang(pa),
  .wiki >>> h5:lang(pa),
  .wiki >>> h6:lang(pa),
  .wiki >>> h2:lang(pi),
  .wiki >>> h3:lang(pi),
  .wiki >>> h4:lang(pi),
  .wiki >>> h5:lang(pi),
  .wiki >>> h6:lang(pi),
  .wiki >>> h2:lang(sa),
  .wiki >>> h3:lang(sa),
  .wiki >>> h4:lang(sa),
  .wiki >>> h5:lang(sa),
  .wiki >>> h6:lang(sa),
  .wiki >>> h2:lang(ta),
  .wiki >>> h3:lang(ta),
  .wiki >>> h4:lang(ta),
  .wiki >>> h5:lang(ta),
  .wiki >>> h6:lang(ta),
  .wiki >>> h2:lang(te),
  .wiki >>> h3:lang(te),
  .wiki >>> h4:lang(te),
  .wiki >>> h5:lang(te),
  .wiki >>> h6:lang(te) {
    line-height: 1.4em;
  }
  .wiki >>> h2:lang(th) {
    line-height: 1.6;
  }
  .wiki >>> .toc,
  .wiki >>> .toccolours {
    border: 1px solid #a2a9b1;
    background-color: #f8f9fa;
    padding: 5px;
    font-size: 95%;
  }
  .wiki >>> .toc {
    display: table;
    padding: 7px;
  }
  .wiki >>> .toc h2 {
    display: inline;
    border: 0;
    padding: 0;
    font-size: 100%;
    font-weight: bold;
  }
  .wiki >>> .toc .toctitle {
    text-align: center;
  }
  .wiki >>> .toc ul {
    list-style: none;
    margin: 0.3em 0;
    padding: 0;
    text-align: left;
  }
  .wiki >>> .toc ul ul {
    margin: 0 0 0 2em;
  }
  .wiki >>> table.toc {
    border-collapse: collapse;
  }
  .wiki >>> table.toc td {
    padding: 0;
  }
  .wiki >>> .tocnumber,
  .wiki >>> .toctext {
    display: table-cell;
    text-decoration: inherit;
  }
  .wiki >>> .tocnumber {
    color: #202122;
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki >>> .mw-content-ltr .tocnumber {
    padding-left: 0;
    padding-right: 0.5em;
  }
  .wiki >>> .mw-content-rtl .tocnumber {
    padding-left: 0.5em;
    padding-right: 0;
  }
  .wiki >>> .toctogglecheckbox {
    display: inline !important;
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .wiki >>> .toctogglespan {
    font-size: 94%;
  }
  .wiki >>> .toctogglespan:before {
    content: " [";
  }
  .wiki >>> .toctogglespan:after {
    content: "]";
  }
  .wiki >>> .toctogglelabel {
    cursor: pointer;
    color: #0645ad;
  }
  .wiki >>> .toctogglelabel:hover {
    text-decoration: underline;
  }
  .wiki >>> .toctogglecheckbox:focus + .toctitle .toctogglelabel {
    text-decoration: underline;
    outline: dotted 1px;
    outline: auto -webkit-focus-ring-color;
  }
  .wiki >>> .toctogglecheckbox:checked + .toctitle .toctogglelabel:after {
    content: "mostrar";
  }
  .wiki >>> .toctogglecheckbox:not(:checked) + .toctitle .toctogglelabel:after {
    content: "ocultar";
  }
  .wiki >>> .toc .toctitle {
    direction: ltr;
  }
  .wiki >>> .mw-content-ltr .toc ul,
  .wiki >>> .mw-content-rtl .mw-content-ltr .toc ul {
    text-align: left;
  }
  .wiki >>> .mw-content-rtl .toc ul,
  .wiki >>> .mw-content-ltr .mw-content-rtl .toc ul {
    text-align: right;
  }
  .wiki >>> .mw-content-ltr .toc ul ul,
  .wiki >>> .mw-content-rtl .mw-content-ltr .toc ul ul {
    margin: 0 0 0 2em;
  }
  .wiki >>> .mw-content-rtl .toc ul ul,
  .wiki >>> .mw-content-ltr .mw-content-rtl .toc ul ul {
    margin: 0 2em 0 0;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx),
  (min-resolution: 144dpi) {
  .wiki >>> .mw-wiki-logo {
    background-image: url(/static/images/project-logos/eswiki-1.5x.png);
    background-size: 135px auto;
  }
}
@media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 2dppx),
  (min-resolution: 192dpi) {
  .wiki >>> .mw-wiki-logo {
    background-image: url(/static/images/project-logos/eswiki-2x.png);
    background-size: 135px auto;
  }
}
.wiki >>> .wb-langlinks-link {
  line-height: 1.125em;
  font-size: 0.75em;
  float: right;
}
.wiki >>> .wb-langlinks-link {
  list-style: none;
  text-align: right;
  padding-right: 0.5em !important;
}
.wiki >>> .wb-langlinks-link > a:before {
  content: "";
  background-image: url(/w/extensions/Wikibase/client/resources/images/edit.svg?34bc5);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 2px;
  vertical-align: top;
}
.wiki >>> .wb-langlinks-link > a:link,
.wiki >>> .wb-langlinks-link > a:visited {
  color: #54595d !important;
}
.wiki >>> .wb-langlinks-link > a:link:before,
.wiki >>> .wb-langlinks-link > a:visited:before {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.73;
}
.wiki >>> .wb-langlinks-link > a:hover {
  color: #0645ad !important;
}
.wiki >>> .wb-langlinks-link > a:hover:before {
  -webkit-filter: none;
  filter: none;
  opacity: 1;
}
.wiki >>> div.after-portlet-lang:after {
  content: "";
  clear: both;
  display: block;
}
.wiki >>> .skin-vector:not(.skin-vector-legacy) .wb-langlinks-link {
  text-align: left;
  float: none;
}
.wiki >>> .skin-vector:not(.skin-vector-legacy) .wb-langlinks-link a {
  color: #0645ad !important;
}
</style>>
